import React from 'react'

export const InputFormAdquisicion = ({ titulo, name, value,change, Disabled = false }) => {

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     if (name === "abono") {
    //         return setform({ ...formulario, [name]: value, pabonousd: formulario.opeusd * (value / 100), pabono: formulario.opepesos * (value / 100) })
    //     }
    //     setform({ ...formulario, [name]: value, pabonousd: formulario.opeusd * (formulario.abono / 100), pabono: formulario.opepesos * (formulario.abono / 100) });

    // };

    return (
        < >
            <label className='element-input'>

                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type='text'
                    name={name}
                    value={value}
                    onChange={(e)=>change(e)}
                    disabled={Disabled}
                />

            </label>
        </>)
}

