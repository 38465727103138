import axios from "axios";
import { url } from "../Url";
export const GetFacturas = async () => {

  const URL = `${url}/despachosyfacturas/obtenerfacturas`;

  try {
    const response = await axios.get(URL);
    
    return await response.data;
   
  } catch (error) {
   
    return window.location.replace(`/paginaError`)
  }
};


