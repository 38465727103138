import React, { useEffect, useState } from 'react'
import { useGetCargos } from '../../Hooks/useGetCargos';

import { useGetPermisos } from '../../Hooks/UseGetPermisos';
import { Modal } from '../../Utility/Modal';
import { Buscador } from '../../Utility/Buscador';
import { FormAgregarPermisos } from '../Components/FormAgregarPermisos';
import { TablaCargos } from '../Components/TablaCargos';

export const PermisosyCargos = () => {
  let {data,loading} =useGetCargos()
  const [filtroNombre, setFiltroNombre] = useState('');
  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const [ShowModal, setShowModal] = useState(false)
  const [Cargos, setCargos] = useState('')
  const [permisos, setpermisos] = useState()
  const  {datap, loadingp} = useGetPermisos([]);
  useEffect(() => {

    if (!loading) {
      setCargos(data);
        setProductosFiltrados(data);
    }

}, [data, loading]);

useEffect(() => {

  if (!loadingp) {
   
    setpermisos(datap);

  }

}, [datap, loadingp]);

  let handleModal = () => {
    setShowModal(!ShowModal)
  }

 

  
  return (
    <div className='bloque-render'>
      <h2>Permisos y Cargos</h2>
      <hr />
      <br />
      <Buscador
                        filtroNombre={filtroNombre}
                        setFiltroNombre={setFiltroNombre}
                        setProductosFiltrados={setProductosFiltrados}
                        Lista={Cargos}
                        placeholder='Buscar por cargo'
                    /> 
        <button onClick={handleModal} className='element-boton agregar-permiso' style={{borderRadius:'10px'}}>Agregar Permiso</button>

        <TablaCargos Cargo={productosFiltrados} data={permisos} loading={loadingp} handleModal={handleModal}/>
      <Modal showModal={ShowModal} closeModal={handleModal}>
        <FormAgregarPermisos data={permisos} loading={loadingp} handleModal={handleModal}/>
      </Modal>

    </div>
  )
}
