import React, { useEffect, useState } from 'react'
import { Modal } from '../../Utility/Modal'
import { FormDespacho } from './FormDespacho'
import { DetalleFactura } from './DetalleFactura'
import { Descargarpdf } from '../../Utility/descargaitem'
import { url } from '../../Url'

export const TablaDespachos = ({ Despachos =[]}) => {

  const [showModaleliminar, setshowModaleliminar] = useState(false)
  const [showModalproductos, setshowModalproductos] = useState(false)
  const [showModalDespacho, setshowModalDespacho] = useState(false)
  const [modalItem, setModalItem] = useState(null);

  

  const handleModaldespachos = (item) => {


    if (showModalDespacho === false) {
      setModalItem(item)
    } else {
      setModalItem(null);
    }
    setshowModalDespacho(!showModalDespacho);
  };
  const handleModalproductos = (item) => {

    if (showModalproductos === false) {
      setModalItem(item)
    } else {
      setModalItem(null);
    }
    setshowModalproductos(!showModalproductos);
  };

  const handleshoweliminar = (item) => {
    setModalItem(item)
    setshowModaleliminar(!showModaleliminar);
  };

  const handleeliminar = async () => {

    window.location.reload(true)
  };
  return (
    <div>
      <table className='Tablaproducto'>
        <thead><tr>
          <td>Orden de Compra</td>
          <td>Nombre Cliente</td>
          <td>Rut Cliente</td>
          <td>Vendedor</td>
          <td>Monto Total</td>
          <td>OC</td>
          <td>Detalle OC</td>
          <td>Generar Despacho</td>
          <td>Eliminar</td>
        </tr></thead>
        <tbody>
          {Despachos?.map((item, index) => 
         {
          const sumaValorneto = item.Productos?.reduce((acc, producto) => acc + (parseFloat(producto.valorventa)*parseInt(producto.cantidad)), 0) || 0;
          return(

            <tr key={index}>
              <td>{item.ordencompra}</td>

              <td>{item.cliente.nombre}</td>
              <td>{item.cliente.rut}</td>
              <td>{item.vendedor}</td>
              <td>{`$${sumaValorneto.toLocaleString('es-CL')}`}</td>
              <td><img onClick={() => Descargarpdf(`${url}/SYSTEM/Downloadfile/${item.adjunto.Documento}`)} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1707566776/msk2anyl7kachkqz7lgd.png" alt="Logo Descarga PDF" width={"25px"} style={{ cursor: 'pointer' }} /></td>
              <td><img onClick={() => handleModalproductos(item)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1710119967/ozxc0osbpi6mgchsp2lq.png' alt='logo modificar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
              <td><button className='element-boton-despachos' onClick={() => { handleModaldespachos(item) }}>Generar Despacho</button></td>
              <td><img onClick={() => handleshoweliminar(item)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
            </tr>
          )
         })}
        </tbody>

      </table>
      <Modal showModal={showModalproductos} closeModal={handleModalproductos}>
        <DetalleFactura Factura={modalItem} handleModal={handleModalproductos} />
      </Modal>

      <Modal showModal={showModalDespacho} closeModal={() => setshowModalDespacho(!showModalDespacho)}>
        <FormDespacho handleModal={() => setshowModalDespacho(!showModalDespacho)} item={modalItem} />
      </Modal>
      <Modal showModal={showModaleliminar} closeModal={handleshoweliminar}>
        <div className='bloque-adv-eliminar'>

          <strong>Seguro que deseas eliminar el item?</strong>
          <br />
          <br />
          <div className='element-save-bar'>
            <button className="element-boton Holder" onClick={handleshoweliminar}>Cerrar</button>
            <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
          </div>

        </div>
      </Modal>
    </div>
  )
}
