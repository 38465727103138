import axios from "axios";
import { url } from "../Url";
export const GetproductoProveedor = async () => {
    const URL = `${url}/GADM/obtenerproductosproveedor`;
    try {
        const response = await axios.get(URL);
        return await response.data;
    } catch (error) {

        return window.location.replace(`/paginaError`)
    }
};


