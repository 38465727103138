import React, { useEffect, useState } from 'react'
import { Modal } from '../../Utility/Modal'
import { useGetOrdenDeCompra } from '../../Hooks/useGetOrdenDeCompra';
import { DetalleOrdendeCompra } from '../Components/DetalleOrdenDeCompra';
import { LoadingSpinner } from '../../Utility/Spiner';
import { convertirFecha } from '../../Utility/FormatFecha';
import { POSTAction } from '../../Helpers/POST';
import Popup from '../../Utility/PopUpNotificacion';
let gettotal =(productos)=>{
let total=0

productos?.map((item)=>total=parseInt(item.valorventa)*parseInt(item.cantidad))

return total
}
export const ListadeOrdenCompra = () => {
    const [Ordenesdecompra, setOrdenesdecompra] = useState([])
    let {data,loading}=useGetOrdenDeCompra()

    useEffect(() => {

        if (!loading) {
            setOrdenesdecompra(data);
          
        }

    }, [data, loading]);
    const [showModalmodificar, setShowModalmodificar] = useState(false);
    const [showModaldetalles, setShowModaldetalles] = useState(false);
    const [showModaleliminar, setshowModaleliminar] = useState(false)
    const [modalItem, setModalItem] = useState(null);
    const [posicion, setposicion] = useState('')
    const [showPopup, setShowPopup] = useState(false);
  const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

    const handleModalmodificar = (item, index) => {
        if (showModalmodificar === false) {
            setModalItem(item)
        } else {
            setModalItem(null);
        }
        setposicion(index);
        setShowModalmodificar(!showModalmodificar);
    };
    const handleModaldetalles = (item, index) => {
        if (showModaldetalles === false) {
            setModalItem(item)
        } else {
            setModalItem(null);
        }
        setposicion(index);
        setShowModaldetalles(!showModaldetalles);
    };

    let handleshoweliminar = (index) => {
        setshowModaleliminar(!showModaleliminar)
        setposicion(index)
    }
    let handleeliminar = async() => {

        let lista = [...Ordenesdecompra];

        let res=await POSTAction(lista[posicion],'Ventas/eliminarcompra')

        setShowPopup(true);
        setmsgpopup({ tipo: 'exito', msg: "compra eliminada de forma exitosa" })
        if (!res.succes) {
            setmsgpopup({ tipo: 'error', msg: res.estado })
        }
        setTimeout(() => {
            setShowPopup(false);
        }, 4000);
    
        
        lista.splice(posicion, 1);
        setOrdenesdecompra(lista)
        handleshoweliminar()
        
        return res.succes && handleshoweliminar()
    }

    return (
      <>{loading?<LoadingSpinner/>:  <div className='bloque-render'>
        {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
      <h2>Ingreso Orden de Compra</h2>
      <hr />


      <table className='Tablaproducto'>
          <thead>
              <tr><td>Orden de compra</td>
                  <td>Nombre Cliente</td>
                  <td>Fecha</td>
                  <td>Valor Total</td>
                  <td>I.V.A 19%</td>
                  <td>Vendedor</td>
                  <td>Estado</td>
                  <td>Detalle</td>
                  {/* <td>Modificar</td> */}
                  <td>Eliminar</td></tr>
          </thead>
          <tbody>
          {Ordenesdecompra?.map((item, index) => (
                  <tr key={index}>
                     <td>{item.ordencompra}</td>
                      <td>{item.cliente.nombre}</td>
                      <td>{convertirFecha(item.createdAt)}</td>
                      <td>{gettotal(item.Productos).toLocaleString('es-CL')}</td>
                      <td>{parseInt(gettotal(item.Productos)*0.19).toLocaleString('es-CL')}</td>
                      <td>{item.vendedor}</td>
                      <td>{item.estado}</td>
                      <td><img onClick={() => handleModaldetalles(item, index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/w5qf1ic1jlz0jctrvshx.png' alt='logo detalles' width={"25px"} style={{ cursor: 'pointer' }} /></td>
                      <td><img onClick={() => handleshoweliminar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
                  
                     </tr>
              ))}
          </tbody>
      </table>
      <Modal showModal={showModaldetalles} closeModal={handleModaldetalles}>
         <DetalleOrdendeCompra OrdenCompra={modalItem} Productos={modalItem} handleModal={handleModaldetalles}/>
      </Modal>

      <Modal showModal={showModaleliminar} closeModal={handleshoweliminar}>
          <div className='bloque-adv-eliminar'>
                
              <strong>Seguro que deseas eliminar el item?</strong>
              <br />
              <div className='element-save-bar'>
                  <button className="element-boton Holder" onClick={handleshoweliminar}>Cerrar</button>
                  <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
              </div>
             
          </div>
      </Modal>
  </div>}</>
    )
}
