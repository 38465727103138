import React from 'react'
import "../../CSS/FormFacturas.css"
import { FormFactura } from '../Components/FormFactura'
export const IngresarFactura = () => {
  return (
    <div className='bloque-render'>
    <h2>Ingreso de facturas</h2>
    <hr />
    <br />

    <FormFactura/>
    </div>
  )
}
