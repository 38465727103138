import React, { useEffect, useState } from 'react'
import { CreateUser } from '../../Helpers/CreateUser';
import { useGetCargos } from '../../Hooks/useGetCargos';
import { LoadingSpinner } from '../../Utility/Spiner';
import Popup from '../../Utility/PopUpNotificacion';
import { HeaderModal } from '../../Utility/HeaderModal';


let Inputform = ({ titulo, name, type, value, setform, formulario, Disabled = false }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });
    };

    return (
        < >
            <label className='element-input'>
                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={Disabled}
                />
            </label>
        </>)
}
const Selectform = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name }) => {

    const handleChange = (e) => {
        setOpcionSeleccionada({ ...opcionSeleccionada, [name]: e.target.value });
    };

    return (
        <div className='element-input'>
            <label htmlFor="miSelect" className='modifier-titulo'>{titulo}:</label>
            <select className='modifier-input' id="miSelect" value={opcionSeleccionada.proveedor} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}
export const FormAgregarUsuario = ({ Usuario, Setusuario, handleModal, setProductosFiltrados }) => {
    const [cargos, setcargos] = useState([])
    const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

    let estado = { "Habilitado": "Habilitado", "Deshabilitado": "Deshabilitado" }
    const [agregarusuario, setagregarusuario] = useState({ rut: '', nombres: '', apellidos: '', cargo: '', loginName: '', estado: '', password: '', hidden: false })
    let { data, loading } = useGetCargos()

    useEffect(() => {
        if (!loading) {
            setcargos(Object.fromEntries(

                data.map(objeto => [objeto.nombrecargo, objeto.nombrecargo])
                //[objeto.cliente.nombre, objeto.cliente.nombre]
            ))
        }

    }, [data])


    let handleagregaruser = async () => {
       
        let res = await CreateUser(agregarusuario)
        
        setmsgpopup({ tipo: 'exito', msg: "Usuario creado exitosamente" })
       
        if (!res.succes) {
            setmsgpopup({ tipo: 'error', msg: res.estado })
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 4000);
       
            return 0
        }
        setShowPopup(true);
        setTimeout(() => {
            setShowPopup(false);
        }, 4000);
       
        handleModal();
        return res.succes && window.location.reload();

    }

    return (
        <div>
            {loading ? <div style={{ width: "400px" }}><LoadingSpinner /></div> : <>
                {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
                <HeaderModal titulo='Agregar Usuario' handleModal={handleModal}/>
                <hr />
                <br />
                <Inputform
                    titulo='RUT'
                    name='rut'
                    type='text'
                    value={agregarusuario.rut}
                    setform={setagregarusuario}
                    formulario={agregarusuario}
                />
                <Inputform
                    titulo='Nombres'
                    name='nombres'
                    type='text'
                    value={agregarusuario.nombres}
                    setform={setagregarusuario}
                    formulario={agregarusuario}
                />
                <Inputform
                    titulo='Apellidos'
                    name='apellidos'
                    type='text'
                    value={agregarusuario.apellidos}
                    setform={setagregarusuario}
                    formulario={agregarusuario}
                />
                <Inputform
                    titulo='Login Name'
                    name='loginName'
                    type='text'
                    value={agregarusuario.loginName}
                    setform={setagregarusuario}
                    formulario={agregarusuario}
                />
                <Inputform
                    titulo='Contraseña'
                    name='password'
                    type='password'
                    value={agregarusuario.password}
                    setform={setagregarusuario}
                    formulario={agregarusuario}
                />
                <Selectform
                    opciones={cargos}
                    titulo='Cargo'
                    name='cargo'
                    opcionSeleccionada={agregarusuario}
                    setOpcionSeleccionada={setagregarusuario}
                />
                <Selectform
                    opciones={estado}
                    titulo='Estado'
                    name='estado'
                    opcionSeleccionada={agregarusuario}
                    setOpcionSeleccionada={setagregarusuario}
                />
                <br />
                <br />
                <div className='element-save-bar'>
                    <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                    <button className="element-boton agregar" onClick={handleagregaruser}>Crear</button>
                </div>
            </>}
        </div>
    )
}
