import React, { useEffect, useState } from 'react'

const Selectform = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name }) => {

    const handleChange = (e) => {
        setOpcionSeleccionada({ ...opcionSeleccionada, [name]: e.target.value });
    };

    return (
        <div className='element-input' >
            <label htmlFor="miSelect" className='modifier-titulo'>{titulo}</label>
            <select className='modifier-input' id="miSelect" value={opcionSeleccionada.estado} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}
let Inputform = ({ titulo, name, type, value, setform, formulario, Disabled = false }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });
    };

    return (
        < >
            <label className='element-input'>
                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={Disabled}
                />
            </label>
        </>)
}

export const IngresarStock = ({ item=[], handleModal ,posicion,Productos,setProductos}) => {
   
    const [nuevostock, setnuevostock] = useState({ lote: "", unidades: item.Cantidad, unidadcaja: "",ncajas:"",ubicacion:'' })
     useEffect(() => {
     setnuevostock({...nuevostock,unidades:item.Cantidad})
     }, [item])
     
    let handlesave=()=>{
        
        const nuevoproducto = [...Productos.productos];
     
        let nuevodetalle = nuevoproducto[posicion]
       
       nuevodetalle.detalle=nuevostock
       nuevodetalle.detalle.ncajas= parseFloat(nuevostock.unidades / nuevostock.unidadcaja).toFixed(2)
      
       setProductos({...Productos,productos:nuevoproducto});
       handleModal()
        
    }
    return (
        <div>
            <span>Ingreso de Stock</span>

            <hr />
            <br />
            <Inputform
                titulo='Proveedor'
                type='text'
                value={item.Proveedor}
                Disabled={true}
            />
            <Inputform
                titulo='Nombre Producto'
                type='text'
                value={item.Producto}
                Disabled={true}
            />
            <Inputform
                titulo='Lote'
                name='lote'
                type='text'
                value={nuevostock.lote}
                setform={setnuevostock}
                formulario={nuevostock}
                Disabled={false}
            />
            <Inputform
                titulo='Nuevo stock'
                name='unidades'
                type='text'
                value={parseInt(nuevostock.unidades||0)}
                // value={parseInt(nuevostock.unidades||0).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                setform={setnuevostock}
                formulario={nuevostock}
                Disabled={false}
            />
            <Inputform
                titulo='Productos por caja'
                name='unidadcaja'
                type='number'
                value={nuevostock.unidadcaja.toLocaleString('es-CL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                setform={setnuevostock}
                formulario={nuevostock}
                Disabled={false}
            />
            <Selectform opciones={{ D1A:"D1-A",D1B:"D1-B",D1C:"D1-C",D1D:"D1-D", D2A:"D2-A",D2B:"D2-B",D2C:"D2-C",D2D:"D2-D"}}
                    titulo="Ubicacion"
                    name="ubicacion"
                    opcionSeleccionada={nuevostock}
                    setOpcionSeleccionada={setnuevostock}
                />

            <br />
            <br />
            <div className='element-save-bar'>
                <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                <button className="element-boton add" onClick={handlesave}>Agregar</button>
            </div>
        </div>
    )
}
