import axios from "axios";
import { url } from "../Url";
export const GetProveedores = async () => {
  const URL = `${url}/GADM/obtenerproveedores`; 
  try {
    const response = await axios.get(URL);
    return await response.data;
  } catch (error) {
   
    return window.location.replace(`/paginaError`)
  }
};


