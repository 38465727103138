import { useRef, useState } from "react";
import "../CSS/sidebar.css";
import { Link } from "react-router-dom";
import FloatingButton from "../Utility/BotonFlotante";




const Icon = ({ icon }) => (
  <img className="material-symbols-outlined" src={icon} alt="logo" height={"30px"} width={"30px"} />
);

const NavHeader = ({user}) => (
  <header className="sidebar-header">
    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}><button type="button">
      <Icon icon="https://res.cloudinary.com/dgi90lgbq/image/upload/v1705253502/ghlquhk9nqythnia1jcw.png" />
    </button>
      <span>{user}</span></div>
    <img onClick={()=>window.location.replace(`/Login`)} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1714001321/gpamddhyxipi7ias9z6x.png" alt='imagen de logout' width={'25px'} />
  </header>
);



const NavButton = ({
  onClick,
  name,
  icon,
  isActive,
  hasSubNav,
}) => (
  <button
    type="button"
    onClick={() => onClick(name)}
    className={isActive ? "active" : ""}
  >
    {icon && <Icon icon={icon} />}
    <span>{name}</span>
    {hasSubNav && <img alt="arrow down" src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1705254404/dqvbyntcl9tuy7exunrk.png" width={"15px"} height={"15px"} />}
  </button>
);
const SubNavButton = ({
  onClick,
  name,
  icon,
  isActive,
  hasSubNav,
}) => (
  <button
    type="button"
    onClick={() => onClick(name)}
    className={isActive ? "active" : ""}
  >
    {icon && <Icon icon={icon} />}
    <Link className="SubnavButton" to={`/${name.replaceAll(' ', '-')}`} style={{ width: '100%', paddingTop: '8%', paddingBottom: '8%' }}>{name}</Link>

    {hasSubNav && <img alt="arrow down" src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1705254404/dqvbyntcl9tuy7exunrk.png" width={"15px"} height={"15px"} />}
  </button>
);


const SubMenu = ({ item, activeItem, handleClick }) => {
  const navRef = useRef(null);

  const isSubNavOpen = (item, items) =>
    items.some((i) => i === activeItem) || item === activeItem;

  return (
    <div
      className={`sub-nav ${isSubNavOpen(item.name, item.items) ? "open" : ""}`}
      style={{backgroundColor:"white",
        width:!isSubNavOpen(item.name, item.items)
        ? 0
        : '100%',
        height: !isSubNavOpen(item.name, item.items)
          ? 0
          : navRef.current?.clientHeight,
      }}
    >
      <div ref={navRef} className="sub-nav-inner">
        {item?.items.map((subItem, index) => (
          <SubNavButton
            onClick={handleClick}
            name={subItem}
            isActive={activeItem === subItem}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export const Sidebar = ({showsidebar, setshowsidebar}) => {




  const menuItems = JSON.parse(sessionStorage.getItem('Permisos'));
  if (menuItems === null) {
    window.location.replace(`/Login`)
  }
  const {usuario} = JSON.parse(sessionStorage.getItem('metadata'));
  const [activeItem, setActiveItem] = useState("");

  const handleClick = (item) => {
    setActiveItem(item !== activeItem ? item : "");
  };

  return (
    <div>
      
      {showsidebar?<aside className="sidebar">
    <NavHeader user={usuario?usuario:""} />

    {usuario==="Admin"&&<>
      <NavButton
              onClick={  ()=>window.location.replace(`/Home`)}
              name='Home'
              icon='https://res.cloudinary.com/dgi90lgbq/image/upload/v1729466108/iaoclrsnlus3yz73fvvl.png'
              isActive={activeItem === 'Home'}
              hasSubNav={false}
            />
            
    </>}
    {menuItems?.map((item, index) => (
      <div key={index}>{item.items.length>0 &&
        <div key={index}>
          {!item.items && (
            <NavButton
              onClick={handleClick}
              name={item.name}
              icon={item.icon}
              isActive={activeItem === item.name}
              hasSubNav={!!item.items}
            />
          )}
          {item.items && (
            <>
              <NavButton
                onClick={handleClick}
                name={item.name}
                icon={item.icon}
                isActive={activeItem === item.name}
                hasSubNav={!!item.items}
              />
              <SubMenu
                activeItem={activeItem}
                handleClick={handleClick}
                item={item}
              />
            </>
          )}
        </div>}</div>
    ))}
  
    
    {usuario==="Admin"&&<>
      <NavButton
              onClick={handleClick}
              name='Configuración'
              icon='https://res.cloudinary.com/dgi90lgbq/image/upload/v1718629223/wdr8qrprnv2inswrd26d.png'
              isActive={activeItem === 'nombre'}
              hasSubNav={true}
            />
            <SubMenu
            activeItem={activeItem}
            handleClick={handleClick}
            item={{
              name: "Configuración",
              icon: "https://res.cloudinary.com/dgi90lgbq/image/upload/v1718629223/wdr8qrprnv2inswrd26d.png",
              items: ["Bancos", "Paises","shippers","Tipo Venta"],
            }}
          />
    </>}
    
  </aside>:null}
      <FloatingButton setshowsidebar={setshowsidebar} showsidebar={showsidebar} /></div>
  );
};