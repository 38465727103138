import React from 'react'
import BarcodeScanner from './scanner';
import { useState } from 'react';

import Popup from '../../Utility/PopUpNotificacion';
import { GetAction } from '../../Helpers/GET';
import { VentanaEnvio } from './VentanaEnvio';
import { POSTAction } from '../../Helpers/POST';
import '../../CSS/Seguimiento.css'

export const LectorBarras = ({ item, handleModal }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

  const [datos, setdatos] = useState("")
  const [result, setresult] = useState("")


  let searchqr=async(resultado)=>{
    //const existeProducto = item.productosenviados.find(item => item.nombre.some(subItem => subItem['ean-13'].toString().trim() === resultado.toString().trim()));
    
    const existeProducto = item?.productosenviados.find(producto => producto.nombre[0]['ean-13'].toString().trim() === resultado.toString().trim());
     
      if (existeProducto) {

        let res = await GetAction(`GADM/obtenerproducto/${existeProducto.nombre[0]._id}`)
        setdatos(res)
      } else {
       
          setShowPopup(true);
          setmsgpopup({ tipo: 'error', msg: "Producto no encontrado" })

          setTimeout(() => {
            setShowPopup(false);
          }, 4000);

          setresult("")
      }
  }

let senddespacho=async()=>{


  let res=await POSTAction(item,"despachosyfacturas/actualizardespacho")

    setShowPopup(true);
    setmsgpopup({ tipo: 'exito', msg: "Productos asignados correctamente" })
    if (!res.succes) {
        setmsgpopup({ tipo: 'error', msg: res.estado })
    }
    setTimeout(() => {
        setShowPopup(false);
    }, 4000);
    return res.succes && handleModal();

}

  return (
    <div>
      {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
      <div className='header-flex'>
        <h3>Lector de Barras</h3>
        <button onClick={handleModal} style={{ border: "none", fontSize: "20px", color: "red", padding: "10px" }}><strong>X</strong></button>
      </div>
      <hr /> 
      {result === "" || datos ==="" ? <>
        <table className='Tablaproducto'>
          <thead><tr>
            <td>Codigo</td>
            <td>Nombre</td>
            <td>Cantidad</td>
            <td>Cantidad Cargada</td>
          </tr></thead>
          <tbody>
            {item?.productosenviados?.map((obj, index) => (
              <tr key={index}>
                <td>{obj.nombre[0].codigo}</td>
                <td>{obj.nombre[0].nombre}</td>
                <td>{obj.cantidadDespachada}</td>
                <td>{parseInt(obj.cantidadcargada) || 0}</td>
              </tr>
            ))}
          </tbody>

        </table>
        <BarcodeScanner result={result} setresult={setresult} searchqr={searchqr} item={item} setShowPopup={setShowPopup} setmsgpopup={setmsgpopup}/>
        
        <div className='element-save-bar'>
                  <button className="element-boton cerrar" onClick={()=>handleModal()}>Cerrar</button>
                  <button className="element-boton guardar-Despacho" onClick={()=>{senddespacho()}}>Guardar</button>
                </div>
        </> :
        
        
       <div style={{width:"400px",minHeight:"300px"}}><VentanaEnvio datos={datos} setresult={setresult} item={item}/></div> }

      {/* <button className='element-boton escanear'>Escanear Producto</button> */}


    </div>



  )
}
