import React, { useState } from 'react'
import { Modal } from '../../Utility/Modal'

import { DeleteCargo } from '../../Helpers/DeleteCargo'
import { FormEditarPermisos } from './FormAgregarPermisosedit'



export const TablaCargos = ({ Cargo, data, loading }) => {

  const [showeliminar, setshoweliminar] = useState(false)
  const [showmodificar, setshowmodificar] = useState(false)
  const [item, setitem] = useState([])
  const [Posicion, setPosicion] = useState('')

  let handleModaleliminar = (index) => {
    setshoweliminar(!showeliminar)
    setPosicion(index)
  }
  let handleModalModificar = (index) => {

    setitem(Cargo[index])
    setshowmodificar(!showmodificar)


  }
  let handleeliminar = async () => {
    await DeleteCargo(Cargo[Posicion])
    return window.location.reload(true)
  }

  return (
    <>
      <table className='Tablaproducto'>
        <thead><tr>
          <td>Nombre Cargo</td>
          <td>Modificar</td>
          <td>Eliminar</td>
        </tr></thead>
        <tbody>
          {Cargo?.map((item, index) => (
            <tr key={index}>
              <td>{item.nombrecargo}</td>
              <td><img onClick={() => handleModalModificar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/uvowjb2taevrvvb2qcdq.png' alt='logo modificar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
              <td><img onClick={() => handleModaleliminar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
            </tr>
          ))}
        </tbody>

      </table>
      <Modal showModal={showeliminar} closeModal={handleModaleliminar}>
        <div className='bloque-adv-eliminar'>

          <strong>Seguro que deseas eliminar el item?</strong>
          <br />
          <br />
          <div className='element-save-bar'>
            <button className="element-boton Holder" onClick={handleModaleliminar}>Cerrar</button>
            <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
          </div>

        </div>
      </Modal>
      <Modal showModal={showmodificar} closeModal={() => setshowmodificar(!showmodificar)}>
        <FormEditarPermisos data={data} loading={loading} handleModal={() => setshowmodificar(!showmodificar)} predata={item} nombre={item.nombrecargo} />
      </Modal>
    </>
  )
}
