
import React, { useEffect, useState } from 'react'
import { LoadingSpinner } from '../../Utility/Spiner'
import '../style/Picking.css'

import { Modal } from '../../Utility/Modal'
import { useGetAction } from '../../Hooks/useGetAction'
import { TablaBodega } from '../Components/TablaBodega'


export const Picking = () => {
    let { loading, data } = useGetAction('despachosyfacturas/obtenerdespachos')
    const [Picking, setPicking] = useState([])
    const [showModal, setshowModal] = useState(false)

    useEffect(() => {
     if(!loading){
   
        setPicking(data);
        
     }
    }, [data,loading])
    


    let handleModal = () => {
        setshowModal(!showModal)
    }

    return (
        <>

            {loading ? <LoadingSpinner /> : <div className='bloque-render'>
                <div style={{ display: "flex",}}>
                    <h2>Picking</h2> 
                    

                </div>
                <hr />
                <br />
                <TablaBodega Bodega={Picking} setBodega={Picking}/>

                <Modal showModal={showModal} closeModal={handleModal} >
                    {/* <FormAgregarbanco handleModal={handleModal}/> */}
                </Modal>
            </div>}
        </>
    )
}
