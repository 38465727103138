import React, { useState } from 'react';
import { UploadFile } from '../../Helpers/UploadFile';
import { UpdateAdquisicion } from '../../Helpers/UpdateAdquisicion';

export const DragAndDrop = ({ indexdocumento, Adquisiciones, setadquisiciones, index, handleModal, indexitem }) => {
    const [dragging, setDragging] = useState(false);
    const [upload, setupload] = useState(false);
    const [nameFile, setnameFile] = useState('');
    const fileInputRef = React.useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        setDragging(false);
        const files = Array.from(e.dataTransfer.files);
        processFiles(files);
    };

    const handleFileSelect = (e) => {
        const files = Array.from(e.target.files);
        processFiles(files);
    };

    const processFiles = async (files) => {
        if (!files.length) {
            
            return;
        }
        const file = files[0];

        const newName = `${Adquisiciones[indexitem]._id}_${file.name}`;
        setnameFile(newName);
        setupload(true);

        const renamedFile = new File([file], newName, { type: file.type });
        // Crear un nuevo archivo con el nombre renombrado
        await UploadFile([renamedFile] );

    };

    const handlesave = async () => {
        const nuevasAdquisiciones = [...Adquisiciones];
        if (nuevasAdquisiciones[index] && nuevasAdquisiciones[index].documentos[indexdocumento]) {
            nuevasAdquisiciones[index].documentos[indexdocumento].nombre = nameFile;
            nuevasAdquisiciones[index].documentos[indexdocumento].url = nuevasAdquisiciones[index].noperacion;
   
            setadquisiciones(nuevasAdquisiciones);
        } else {
            console.error('El elemento o documento especificado no existe en el arreglo.');
        }
        await UpdateAdquisicion(nuevasAdquisiciones[index]);
        handleModal();
    };

    return (
        <>
            {upload ? (
                <div
                    style={{
                        border: `2px dashed gray`,
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '600px',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <img src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1707789865/scbd22bvij00rkiec25v.png" alt="Icono PDF" width={'100px'} />
                    <span>{nameFile}</span>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                        <button onClick={handleModal} className='element-boton cerrar'>Cerrar</button>
                        <button onClick={handlesave} className='element-boton guardar'>Guardar</button>
                    </div>
                </div>
            ) : (
                <div>
                    <div
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        onClick={() => fileInputRef.current.click()}
                        style={{
                            border: `2px dashed ${dragging ? 'blue' : 'gray'}`,
                            padding: '20px',
                            borderRadius: '10px',
                            textAlign: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        <h2>Arrastra y suelta archivos aquí <br />o <br />haz clic para seleccionar un archivo</h2>
                        <input
                            ref={fileInputRef}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileSelect}
                        />
                    </div>
                </div>
            )}
        </>
    );
}