import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
} from 'chart.js';

// Registrar las escalas necesarias
ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale
);

export const SalesChart = () => {
  const data = {
    labels: [
      '1 de octubre',
      '2 de octubre',
      '3 de octubre',
      '4 de octubre',
      '5 de octubre',
      '6 de octubre',
      '7 de octubre',
      '8 de octubre',
      '9 de octubre',
      '10 de octubre',
      '11 de octubre',
      '12 de octubre',
      '13 de octubre',
      '14 de octubre',
    ],
    datasets: [
      {
        // label: 'Ventas', // Eliminar esta línea
        data: [200000, 250000, 3000000, 15000, 400000, 3500000, 5000000, 400050, 300000, 40000, 5500000, 0, 70000, 1223355],
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 0.2)', // Color de fondo (no se verá ya que fill es false)
        borderColor: 'rgba(0, 123, 255, 1)', // Color azul para la línea
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Permitir que el gráfico ocupe todo el espacio disponible
    plugins: {
      legend: {
        display: false, // También puedes ocultar la leyenda si no quieres mostrar ninguna etiqueta
      },
      tooltip: {
        mode: 'index',
      },
    },
    scales: {
      x: {
        type: 'category',
        grid: {
          display: false, // Desactivar la cuadrícula en el eje X
        },
      },
      y: {
        grid: {
          display: false, // Desactivar la cuadrícula en el eje Y
        },
      },
    },
  };
  return (
    <div style={{ width: '100%', height: '43vh',padding:"15px" }}> {/* Ajusta la altura según sea necesario */}
      <h2>Ventas de los últimos 14 días</h2>
      <br />
      <Line data={data} options={options} />
    </div>
  );
};
