import React, { useEffect, useState } from 'react'
import { formatNumber } from '../../Utility/formarnumber';
import { Modal } from '../../Utility/Modal'
import { convertirFecha } from '../../Utility/FormatFecha';
import { useGetAction } from '../../Hooks/useGetAction';
import { LoadingSpinner } from '../../Utility/Spiner';
import { IngresarStock } from '../../ControlStock/Components/IngresarStock';
import { InputFormAdquisicion } from '../Section/inputFormAdquisicion';
const Selectformenvio = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name, posicion = 0 }) => {

    const handleChange = (e) => {
        setOpcionSeleccionada({ ...opcionSeleccionada, envio: { ...opcionSeleccionada.envio, [name]: e.target.value } });
    };

    return (
        <div className='element-input'>
            <select className='modifier-input' id="miSelect" value={opcionSeleccionada.proveedor} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}
const Selectform = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name, posicion = 0 }) => {

    const handleChange = (e) => {
        const nuevoObjetoCompleto = {
            ...opcionSeleccionada,
            abonos: opcionSeleccionada.abonos.map((abono, currentIndex) => {
                if (currentIndex === posicion) {
                    return { ...abono, [name]: e.target.value }
                }
                return abono;
            })
        };
        return setOpcionSeleccionada(nuevoObjetoCompleto)

    };

    return (
        <div className='element-input'>
            <select className='modifier-input' id="miSelect" value={opcionSeleccionada.proveedor} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}

let Inputform = ({ name, type, value, setform, formulario, Disabled = false, types = '', posicion = 0, calcularPromedioVdolar }) => {

    const handleChange = (e) => {


        const { name, value } = e.target;

        if (types === 'abonos') {
            calcularPromedioVdolar()
            const nuevoObjetoCompleto = {
                ...formulario,
                abonos: formulario.abonos.map((abono, currentIndex) => {
                   
                    if (currentIndex === posicion) {
                     
                        
                        if (name === 'abono') {
                            
                           if(value===""){
                            return { ...abono, [name]: 0,pabono: 0, pabonousd:0 }
                           }
                      
                           
                           console.log(formulario.opeusd);
                           return { ...abono, [name]: parseInt(value),pabono:(abono.vdolar*formulario.opeusd) * (parseInt(value) / 100), pabonousd: formulario.opeusd * (parseInt(value) / 100) }

                        }
                    if(name==='vdolar' || name==='cae'){
                        let value = e.target.value.replace(/[^\d,]/g, ''); // Elimina todo lo que no sea un número o coma decimal
        
                        if (value) {
                          // Reemplaza la coma por un punto decimal para poder trabajar con los decimales
                          value = value.replace(',', '.');
                        
                          // Divide en parte entera y decimal
                          const [integerPart, decimalPart] = value.split('.');
                        
                          // Formatea la parte entera (separando miles con puntos)
                          let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                        
                          // Vuelve a juntar la parte entera formateada con la parte decimal si existe
                          let formattedValue = decimalPart !== undefined 
                            ? `${formattedIntegerPart},${decimalPart}` 
                            : formattedIntegerPart;
                        
                     console.log(formulario);
                     
                            return name==='cae'?{ ...abono, [name]: formattedValue}:{ ...abono, [name]: formattedValue,pabono:(value*formulario.opeusd) * (parseInt(abono.abono) / 100) }
                        } else {
                          return{ ...abono, [name]: ''} ; // Si no hay valor, limpia el campo
                        }
                    }else{
                        return { ...abono, [name]: value }}
                    }
                    return abono;
                })
            };
       
            
            const sumaAbonos = nuevoObjetoCompleto.abonos.reduce((suma, abono) => suma + parseInt(abono.abono||0), 0);
       
            
                return sumaAbonos <= 100 && setform(nuevoObjetoCompleto)                           
            // return sumaAbonos <= 100 || isNaN(sumaAbonos) && setform(nuevoObjetoCompleto)
       
        }

        const nuevoObjetoCompleto = { ...formulario, envio: { ...formulario.envio, [name]: value } };

        setform(nuevoObjetoCompleto);


    };


    return (
        < >
            <input className='modifier-input adquisiciones'
                type={type}
                name={name}
                value={value}
                onChange={handleChange}
                disabled={Disabled}
            />
        </>)
}

export const DetalleSeguimiento = ({ adquisicion, setadquisicion }) => {

    
    let { loading, data } = useGetAction('Conf/obtenershippers')
    const [Shipper, setShipper] = useState([])
 

    useEffect(() => {
     if(!loading){
        const transformedObject = data?.reduce((acc, item) => {
            // Convertir 'nombre' a minúsculas y eliminar caracteres no alfanuméricos para usarlo como clave
            const key = item.nombre.toLowerCase().replace(/[^a-z0-9]/gi, '');
        
            // Convertir la primera letra a mayúsculas para usarla como valor
            const value = item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase();
        
            acc[key] = value;
            return acc;
        }, {});
  
        setShipper(transformedObject);
        
     }
    }, [data,loading])


    let Banco = useGetAction('Conf/obtenerbancos')
    const [Bancos, setBancos] = useState([])
  

    useEffect(() => {
     if(!Banco?.loading){
        const transformedObject = Banco?.data?.reduce((acc, item) => {
            // Convertir 'nombre' a minúsculas y eliminar caracteres no alfanuméricos para usarlo como clave
            const key = item.nombre.toLowerCase().replace(/[^a-z0-9]/gi, '');
        
            // Convertir la primera letra a mayúsculas para usarla como valor
            const value = item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase();
        
            acc[key] = value;
            return acc;
        }, {});
        setBancos(transformedObject);
        
     }
    }, [Banco.data,Banco.loading])
    
    
    const [MontoAdquisicion, setMontoAdquisicion] = useState('')
    const [rowsabono, setrowsabono] = useState('')
    const [dolarpromedio, setdolarpromedio] = useState('')
    const [showModalmodificar, setShowModalmodificar] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [posicion, setposicion] = useState('')

    const handleModalmodificar = (item, index) => {
        if (showModalmodificar === false) {
            setModalItem(item)
        } else {
            setModalItem(null);
        }
        setposicion(index);
        setShowModalmodificar(!showModalmodificar);
    };

    const calcularPromedioVdolar = () => {
        let totalVdolar = 0;
        adquisicion.abonos.forEach((abono) => {
            totalVdolar += parseFloat(abono.vdolar || 0);
        });
        return setdolarpromedio(totalVdolar / adquisicion.abonos.length);
    };

    const contarObjetosAbonos = () => {

        return setrowsabono(adquisicion.abonos.length);
    };
    useEffect(() => {
        const sumTotal = {
            pabono: 0,
            cae: 0
        };

        // Iterar sobre cada objeto en la matriz de abonos
        adquisicion.abonos.forEach(abono => {
            // Sumar los valores de pabono y cae
            sumTotal.pabono += parseFloat(abono.pabono||0);
            sumTotal.cae += parseFloat(abono.cae.replace(',','.')||0);
        });
        let cae=sumTotal.cae
        console.log(cae);
        
        setMontoAdquisicion(parseFloat(sumTotal.pabono) + parseFloat(cae))
    }, [adquisicion.abonos])


    useEffect(() => {
        calcularPromedioVdolar()
        contarObjetosAbonos()

    }, [])
    let handleagregar = () => {
        const productosActualizados = [...adquisicion.abonos];
        const ultimoAbono = productosActualizados[productosActualizados.length - 1];
        //Validacion de item vacio antes de agregar otro
        
        const ultimoAbonoVacio = Object.values(ultimoAbono).every(value => value === "");
        
        if (ultimoAbonoVacio) {
            return console.log("El último abono está vacío.");
        }
        
        const sumaAbonos = adquisicion.abonos.reduce((suma, abono) => suma + parseInt(abono.abono), 0);
       
        if(sumaAbonos >= 100 || isNaN(sumaAbonos)){
            return 0
        }
    

        productosActualizados.push({
            abono: "",
            fecha: "",
            pabono: "",
            pabonousd: "",
            banco: "",
            swift: "",
            cae: "",
            vdolar: '',
        });
      
        setadquisicion({
            ...adquisicion,
            abonos: productosActualizados
        });
        contarObjetosAbonos()

    }
  
    return (
        <div >
            <table className='Tablaproducto'>
                <thead>
                    <tr>
                        <td>Número Operación</td>
                        <td>Tipo Operación</td>
                        <td>PI</td>
                        <td>SWIFT</td>
                        <td>Fecha producción</td>
                        {/* <td>Valor Dolar Promedio</td> */}
                        <td>Monto PI</td>
                        <td>Monto Pesos</td>

                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td>{adquisicion.noperacion}</td>
                        <td>{adquisicion.toperacion}</td>
                        <td>{adquisicion.pi}</td>
                        <td>{adquisicion.swift}</td>
                        <td>{convertirFecha(adquisicion.fecha)}</td>
                        {/* <td>{formatNumber(dolarpromedio)}</td> */}
                        <td>USD {formatNumber(adquisicion.opeusd)}</td>
                        <td>${adquisicion.opepesos.toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                    </tr>

                </tbody>
            </table>

            <div className='modifier-separador'><span>Pagos Parciales</span><button onClick={handleagregar} className='modifier-botton agregar'>Agregar</button></div>
            <table className='Tablaproducto'>
                <thead>
                    <tr>
                        <td>SWIFT</td>
                        <td>Fecha Operación</td>
                        <td>Banco</td>
                        <td>Valor USD</td>
                        <td>Porcentaje</td>
                        <td>Abono USD</td>
                        <td>Abono $</td>
                        {/* <td>Comsión Banco</td> */}
                        <td>Comsión CLP</td>

                    </tr>
                </thead>
                <tbody>
                    {adquisicion?.abonos?.map((item, index) => (
                        <tr key={index}>
                            {index !== rowsabono ?
                                <>
                                    <td>{item.swift}</td>
                                    <td>{convertirFecha(item.fecha)}</td>
                                    <td>{item.banco}</td>
                                    <td>{formatNumber(item.vdolar)}</td>
                                    <td>{item.abono}%</td>
                                    <td>USD {formatNumber(item.pabonousd)}</td>
                                    <td>${item.pabono.toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                                    <td>${item.cae}</td>
                                    {/* <td>${(item.cae * item.vdolar).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td> */}

                                </>
                                : <>
                                    <td><Inputform
                                        name='swift'
                                        type='text'
                                        value={item.swift}
                                        setform={setadquisicion}
                                        formulario={adquisicion}
                                        types='abonos'
                                        posicion={index}
                                        calcularPromedioVdolar={calcularPromedioVdolar} /></td>
                                    <td><Inputform
                                        name='fecha'
                                        type='date'
                                        value={item.fecha}
                                        setform={setadquisicion}
                                        formulario={adquisicion}
                                        types='abonos'
                                        posicion={index}
                                        calcularPromedioVdolar={calcularPromedioVdolar} /></td>
                                    <td>{loading?<LoadingSpinner/>:<Selectform
                                        name="banco"
                                        opciones={Bancos}
                                        titulo="banco"
                                        opcionSeleccionada={adquisicion}
                                        setOpcionSeleccionada={setadquisicion}
                                        posicion={index} />}
                                    </td>
                                    <td>
                                        
                                        <Inputform
                                        name='vdolar'
                                        type='text'
                                        value={item.vdolar}
                                        setform={setadquisicion}
                                        formulario={adquisicion}
                                        types='abonos'
                                        posicion={index}
                                        calcularPromedioVdolar={calcularPromedioVdolar} /></td>
                                    <td><Inputform
                                        name='abono'
                                        type='text'
                                        value={item.abono}
                                        setform={setadquisicion}
                                        formulario={adquisicion}
                                        types='abonos'
                                        posicion={index}
                                        calcularPromedioVdolar={calcularPromedioVdolar} /></td>
                                    <td><Inputform
                                        name='pabonousd'
                                        type='text'
                                        Disabled={true}
                                        value={formatNumber(item.pabonousd)}
                                        calcularPromedioVdolar={calcularPromedioVdolar} /></td>
                                    <td><Inputform
                                        name='pabono'
                                        type='text'
                                        Disabled={true}
                                        value={formatNumber(item.pabono)}
                                        calcularPromedioVdolar={calcularPromedioVdolar} /></td>


                                    <td><Inputform
                                        name='cae'
                                        type='number'
                                        value={item.cae}
                                        setform={setadquisicion}
                                        formulario={adquisicion}
                                        types='abonos'
                                        posicion={index}
                                        calcularPromedioVdolar={calcularPromedioVdolar} /></td>
                                    <td></td>

                                </>}</tr>

                    ))}
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td><strong>Total</strong></td>
                        <td><strong>USD {isNaN(MontoAdquisicion) ? '0' :(MontoAdquisicion/dolarpromedio).toLocaleString('es-CL',{ minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong></td>
                        <td><strong>${isNaN(MontoAdquisicion) ? '0' :MontoAdquisicion.toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</strong></td>
                      
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <div className='modifier-separador'><span>Antecedentes de Embarque </span></div>
            <table className='Tablaproducto'>
                <thead>
                    <tr>
                        <td>N° Asignación</td>
                        <td>Shipping</td>
                        <td>Fecha</td>
                        <td>Fecha Embarque ETD</td>
                        <td>Fecha Arribo</td>
                        <td>Valor USD</td>
                        <td>Valor Shipping</td>
                        <td>Valor Shipping $</td>
                     


                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td><Inputform name='nasignacion'
                            type='text'
                            value={adquisicion.envio.nasignacion}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                        <td>
                            {Banco.loading?<LoadingSpinner/>:<Selectform
                                        name="shipping"
                                        opciones={Shipper}
                                        titulo="shipping"
                                        opcionSeleccionada={adquisicion}
                                        setOpcionSeleccionada={setadquisicion}
                                        />}
                          </td>
                        <td><Inputform name='fechacierre'
                            type='date'
                            value={adquisicion.envio.fechacierre}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                        <td><Inputform name='fechaembarque'
                            type='date'
                            value={adquisicion.envio.fechaembarque}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                        <td><Inputform name='fechaarribo'
                            type='date'
                            value={adquisicion.envio.fechaarribo}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                        <td><Inputform name='dolarshipping'
                            type='text'
                            value={formatNumber(adquisicion.envio.dolarshipping)}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                        <td><Inputform name='pdolarshipping'
                            type='text'
                            value={formatNumber(adquisicion.envio.pdolarshipping)}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                        <td><Inputform name='pesosshipping'
                            type='text'
                            value={parseInt(adquisicion.envio.pdolarshipping*adquisicion.envio.dolarshipping).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            setform={setadquisicion}
                            formulario={adquisicion}
                            Disabled="true" /></td>
                     


                    </tr>


                </tbody>
                <thead>
                    <tr>
                    <td>THC</td>
                        <td>THC $</td>
                        <td>Seguro Shipping</td>
                        <td>N° Contenedor</td>
                        <td>N° BL</td>
                        <td>Documento Proveedor</td>
                        <td>CDA</td>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                    <td><Inputform name='thc'
                            type='text'
                            value={adquisicion.envio.thc}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>

                        <td><Inputform name='thcpesos'
                            type='number'
                            value={parseInt(adquisicion.envio.dolarshipping*adquisicion.envio.thc).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            setform={setadquisicion}
                            formulario={adquisicion}
                            Disabled="true" /></td>
                        <td><Inputform name='seguroshipping'
                            type='text'
                            value={parseInt(adquisicion.envio.seguroshipping||0).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                            <td><Inputform name='contenedor'
                            type='text'
                            value={adquisicion.envio.contenedor}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                        <td><Inputform name='bl'
                            type='text'
                            value={formatNumber(adquisicion.envio.bl)}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                        <td>{adquisicion.documentos[0].url === "" ? "NO" : "SI"}</td>
                        <td>{adquisicion.documentos[1].url === "" ? "NO" : "SI"}</td>



                    </tr>
                </tbody>

                <thead>
                    <tr>
                        <td>Gastos</td>
                        <td>Transporte Terrestre</td>
                        <td>N Aprovicionamiento</td>
                        <td>Costo Aduana</td>


                    </tr>
                </thead>
                <tbody>
                    <tr >

                        <td><Inputform name='gastos'
                            type='Number'
                            value={parseInt(adquisicion.envio.gastos).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                        <td><Inputform name='totalpesostransporte'
                            type='text'
                            value={parseInt(adquisicion.envio.totalpesostransporte||0).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>



                        <td><Inputform name='numeroaprv'
                            type='text'
                            value={adquisicion.envio.numeroaprv}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                        <td><Inputform name='valoraduana'
                            type='text'
                            value={parseInt(adquisicion.envio.valoraduana||0).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            setform={setadquisicion}
                            formulario={adquisicion} /></td>
                    </tr>
                    <tr>
                        <td><strong>Total Operación</strong></td>
                        <td><strong>${isNaN(MontoAdquisicion) ? '0' :(MontoAdquisicion +
                            (parseInt(adquisicion.envio.seguroshipping) || 0) +
                            (parseInt(adquisicion.envio.thcpesos) || 0) +
                            (parseInt(adquisicion.envio.pesosshipping) || 0) +
                            (parseInt(adquisicion.envio.gastos) || 0) +
                            (parseInt(adquisicion.envio.totalpesostransporte) || 0)).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</strong></td>

                    </tr>
                </tbody>
            </table>
            <div className='modifier-separador'><span>Productos</span></div>
            <table className='Tablaproducto'>
                <thead>
                    <tr>
                        <td>N</td>
                        <td>Proveedor</td>
                        <td>Producto</td>
                        <td>Cantidad</td>
                        <td>Valor Dolar</td>
                        <td>Valor Pesos</td>
                        <td>Ingreso Stock</td>
                    </tr>
                </thead>
                <tbody>
                    {adquisicion?.productos?.map((item, index) => (
                        <tr key={index}>
                            <td>{index+1}</td>
                            <td>{item.Proveedor}</td>
                            <td>{item.Producto}</td>
                            <td>{parseInt(item.Cantidad).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                            <td>USD {parseInt(item.ValorDolar).toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td>
                            <td>${(item.ValorDolar*(parseInt(adquisicion.vdolar) || 0)).toLocaleString('es-CL')}</td>
                            <td><img onClick={() => handleModalmodificar(item, index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/uvowjb2taevrvvb2qcdq.png' alt='logo modificar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Modal showModal={showModalmodificar} closeModal={handleModalmodificar}>
                <IngresarStock
                    item={modalItem}
                    handleModal={handleModalmodificar}
                    posicion={posicion}
                    Productos={adquisicion}
                    setProductos={setadquisicion}
                />
            </Modal>
        </div>
    )
}
