import React, { useState } from 'react'
import { useEffect } from 'react';


import "../../CSS/ListaProductos.css"
import { Buscador } from '../../Utility/Buscador';
import { Modal } from '../../Utility/Modal'
import { AgregarProducto } from '../Components/AgregarProducto';
import { useGetProductos } from '../../Hooks/useGetProductos';
import { LoadingSpinner } from '../../Utility/Spiner';
import { TablaProductosEdit } from '../../ControlStock/Components/ListaProductosEdit';

export const Productos = () => {
    const [filtroNombre, setFiltroNombre] = useState('');
    const [productosFiltrados, setProductosFiltrados] = useState([]);
    const { data, loading } = useGetProductos();
    const [productos, setProductos] = useState();
    useEffect(() => {
        
        if (!loading) {
            setProductos(data);
            setProductosFiltrados(data);
        }
    }, [data, loading]);
    

    const [showModal, setShowModal] = useState(false);

    const handleModal = () => {
        setShowModal(!showModal);
    };

    return (
        <>
        {loading?<LoadingSpinner/>:<div className='bloque-render'>
            <h2>Manejo de Stock</h2>
            <hr />
            <br />
            <div className='element-botones-accion'>
                <button className='element-boton agregar-productos' style={{borderRadius:'10px'}}  onClick={handleModal}>
                    Agregar Producto
                </button>

                <Buscador
                    filtroNombre={filtroNombre}
                    setFiltroNombre={setFiltroNombre}
                    setProductosFiltrados={setProductosFiltrados}
                    Lista={productos}
                    placeholder='Buscar por Nombre de producto'
                    Campo='nombre'
                />
            </div>
            <TablaProductosEdit
                Productos={productosFiltrados}
                setProductos={setProductosFiltrados} />

            <Modal showModal={showModal} closeModal={handleModal}>
                <AgregarProducto handleModal={handleModal} setProductos={setProductosFiltrados} productos={productos}/>
            </Modal>
        </div>}
        </>
    )
}
