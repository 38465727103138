import React, { useState, useEffect } from 'react';
import '../CSS/Popup.css'; // Archivo de estilos CSS para el mensaje emergente

const Popup = ({ message ,tipo}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 6000); // 6 segundos

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`popup-container ${tipo} ${visible ? 'show' : 'hide'}`}>
      <div className="popup-message">{message}</div>
    </div>
  );
};

export default Popup;