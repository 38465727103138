import React, { useState } from 'react'

import { CrearProveedor } from '../../Helpers/CrearProveedor';
import { HeaderModal } from '../../Utility/HeaderModal';


let Inputform = ({ titulo, name, type, value, setform, formulario }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });
    };

    return (
        < >
            <label className='element-input'>
                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                />
            </label>
        </>)
}

export const FormAgregarProveedor = ({ handleModal }) => {

    const [Poveedor, setPoveedor] = useState({ rut: '', nombre: '', Productos:[],hidden:false })

    let handlecrearcliente = async() => {
        await CrearProveedor(Poveedor)
        window.location.reload(true)
    }

    return (
        <div>
             <HeaderModal titulo='Agregar Proveedor' handleModal={handleModal}/>
            <hr />
            <br />
            <Inputform
                titulo='RUT'
                name='rut'
                type='text'
                value={Poveedor.rut}
                setform={setPoveedor}
                formulario={Poveedor}
            />
            <Inputform
                titulo='Nombre Proveedor'
                name='nombre'
                type='text'
                value={Poveedor.nombre}
                setform={setPoveedor}
                formulario={Poveedor}
            />
            
            <br />
            <div className='element-save-bar'>
                <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                <button className="element-boton add" onClick={handlecrearcliente} style={{width:'120px'}}>Agregar</button>
            </div>
        </div>
    )
}
