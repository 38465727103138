import React, { useState } from 'react'
import { useEffect } from 'react';


import "../../CSS/ListaProductos.css"
import { Buscador } from '../../Utility/Buscador';
import { useGetProductos } from '../../Hooks/useGetProductos';
import { LoadingSpinner } from '../../Utility/Spiner';
import { TablaProductosEdit } from '../Components/ListaProductosEdit';

export const ListaProductosEdit = () => {

  const [productos, setProductos] = useState();
  const [filtroNombre, setFiltroNombre] = useState('');
  const [productosFiltrados, setProductosFiltrados] = useState([]);

  const { data, loading } = useGetProductos();

  useEffect(() => {

    if (!loading) {
      console.log(data);
      setProductos(data);
      setProductosFiltrados(data);
    }
  }, [data, loading]);

  return (
   <> {loading ? <LoadingSpinner/>:<div className='bloque-render'>
   <h2>Manejo de Stock</h2>
   <hr />
   <br />
   <Buscador
     filtroNombre={filtroNombre}
     setFiltroNombre={setFiltroNombre}
     setProductosFiltrados={setProductosFiltrados}
     Lista={productos}
     placeholder='Buscar por Nombre de producto'
     Campo='nombre'
   />
   <TablaProductosEdit
     Productos={productosFiltrados}
     setProductos={setProductosFiltrados} />
 </div>}</>
    
  )
}
