import React, { useEffect, useState } from 'react'
import { useGetOrdenDeCompra } from '../../Hooks/useGetOrdenDeCompra';
import { Modal } from '../../Utility/Modal'
import { FormPago } from './FormPago';
import { createFactura } from '../../Helpers/createFactura'
import { LoadingSpinner } from '../../Utility/Spiner';
import Popup from '../../Utility/PopUpNotificacion';
import { convertirFecha } from '../../Utility/FormatFecha';
import { DragAndDropOrdenCompra } from '../../Ventas_Facturas/Components/DragAndDropIngresoCompra';
let Inputform = ({ titulo, name, type, value, setform, formulario, Disabled = false }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });
    };

    return (
        < >
            <label className='element-input'>
                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={Disabled}
                />
            </label>
        </>)
}
const Selectform = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name, validacion = () => { }, value }) => {

    const handleChange = (e) => {

        setOpcionSeleccionada({ ...opcionSeleccionada, [name]: e.target.value });

        validacion(e.target.value)

    };

    return (
        <div className='element-input'>
            <label htmlFor="miSelect" className='modifier-titulo'>{titulo}</label>
            <select className='modifier-input' id="miSelect" value={value} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}
export const FormFactura = () => {
    const [Modalshowdocumento, setModalshowdocumento] = useState(false)
    const [showFormadepago, setshowFormadepago] = useState(false)
    const [Factura, setFactura] = useState({ cliente: "", ordendecompra: "", infoOrdenCompra: {}, solicitud: "", nFactura: "", fechapago: "", precioneto: "1", preciodescuento: 0, porcentajedescuento: 0, valoriva: "", valortotal: "", metodosdepago: [], Observacion: "", adjunto: { Documento: '', Url: '', Nombre: '' }, estado: "Pendiente", createAt: "", nombre: "" })
    const [Ordenesdecompra, setOrdenesdecompra] = useState([])
    const [Clientes, setClientes] = useState({})
    const [Ordendecompa, setOrdendecompa] = useState({})
    let { data, loading } = useGetOrdenDeCompra()
    const [showeliminar, setshoweliminar] = useState(false)

    const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

    const [Posicion, setPosicion] = useState('')

    let handleModaleliminar = (index) => {
        setshoweliminar(!showeliminar)
        setPosicion(index)
    }


    useEffect(() => {
        if (!loading) {
            setClientes(Object.fromEntries(

                data.filter(objeto => objeto.estado !== 'Facturado').map(objeto => [objeto.cliente.nombre, objeto.cliente.nombre])
                //[objeto.cliente.nombre, objeto.cliente.nombre]
            ))
            setOrdenesdecompra(data);
        }

    }, [data, loading]);


    useEffect(() => {
        let suma = 0;
        let MontoDescuento = 0;



        const ordenesFiltradas = Ordenesdecompra.filter((orden) => {

            return orden.cliente.nombre === Factura.cliente && orden.ordencompra === Factura.ordendecompra;
        });


        ordenesFiltradas.forEach((orden) => {

            orden.Productos.forEach((producto) => {

                suma += parseFloat(producto.valorventa) * parseFloat(producto.cantidad);
            });
        });
        MontoDescuento = suma * (Factura.porcentajedescuento / 100)


        setFactura({ ...Factura, valortotal: parseFloat(suma) + parseFloat((suma * 0.19)) - parseFloat(MontoDescuento) + parseFloat(ordenesFiltradas.costodespacho || 0), valoriva: suma * 0.19 + parseFloat(ordenesFiltradas.costodespacho || 0), preciodescuento: MontoDescuento,pagado:ordenesFiltradas[0]?.pagado })

    }, [Factura.ordendecompra, Factura.porcentajedescuento])


    let handleselectordendecompra = (nombre) => {

        const ordenesDeCompraFormato = Object.fromEntries(
            Ordenesdecompra
                .filter(objeto => objeto.estado !== 'Facturado')
                .filter(objeto => objeto.cliente.nombre === nombre)
                .map(objeto => [objeto.ordencompra, objeto.ordencompra])
        );

        setOrdendecompa(ordenesDeCompraFormato)

    }

    let handleModaldocumento = () => {
        setModalshowdocumento(!Modalshowdocumento)
    }
    let handleshowpago = () => {
        setshowFormadepago(!showFormadepago)
    }
    let handleitem = (e) => {
        const { value } = e.target;
        setFactura({ ...Factura, Observacion: value });
    }
    let handlecrearfactura = async () => {

        if (Factura.cliente === "" || Factura.ordendecompra === "" || Factura.nFactura === "" || Factura.fechapago === "") {

            setmsgpopup({ tipo: 'error', msg: "Existen campos vacíos" })
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 4000);

            return 0
        }

        const cliente = Factura.cliente;
        const ordenCompra = Factura.ordendecompra;

        const objetoEncontrado = Ordenesdecompra.find(objeto => objeto.cliente.nombre === cliente && objeto.ordencompra === ordenCompra);

        let NuevaFactura = { ...Factura, infoOrdenCompra: objetoEncontrado._id }

        let res = await createFactura(NuevaFactura)

        setShowPopup(true);
        setmsgpopup({ tipo: 'exito', msg: "Factura Generada" })
        if (!res.succes) {
            setmsgpopup({ tipo: 'error', msg: res.estado })
        }
        setTimeout(() => {
            setShowPopup(false);
        }, 4000);
        return res.succes && window.location.reload();

    }

    let handleadjunto = () => {
        setFactura({ ...Factura, adjunto: { Documento: '', Url: '', Nombre: '' } })
    }
    let handleeliminar = () => {
        setFactura({...Factura,metodosdepago:Factura.metodosdepago.filter((_, i) => i !== Posicion)} );
        setshoweliminar(!showeliminar)

    }

    return (
        <div >
            {loading ? <LoadingSpinner /> : <>
                <div className='bloque-FormFactura'>
                    {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
                    <Selectform opciones={Clientes}//Clientes
                        titulo="Cliente"
                        name="cliente"
                        opcionSeleccionada={Factura}
                        setOpcionSeleccionada={setFactura}
                        validacion={handleselectordendecompra}
                        value={Factura.cliente}
                    />
                    <Selectform opciones={Ordendecompa}
                        titulo="Ordenes de Compra"
                        name="ordendecompra"
                        opcionSeleccionada={Factura}
                        setOpcionSeleccionada={setFactura}

                        value={Factura.ordendecompra}

                    />
                    <Inputform
                        titulo='N° de Factura'
                        name='nFactura'
                        type='Number'
                        value={Factura.nFactura}
                        setform={setFactura}
                        formulario={Factura}
                    />
                    <Inputform
                        titulo='Fecha de Pago'
                        name='fechapago'
                        type='date'
                        value={Factura.fechapago}
                        setform={setFactura}
                        formulario={Factura}
                    />
                    {/* <Inputform
                        titulo='Precio Neto'
                        name='precioneto'
                        type='text'
                        value={`${parseInt(Factura.valortotal.toLocaleString('es-CL') || 0) - parseInt(Factura.valoriva.toLocaleString('es-CL') || 0)} CLP`}
                        setform={setFactura}
                        formulario={Factura}
                        Disabled={true}
                    /> */}

                </div>

                <br />
                <strong>Descuento</strong>
                <hr />
                <br />
                <div className='bloque-FormFactura'>
                    <Inputform
                        titulo='Porcentaje de Descuento %'
                        name='porcentajedescuento'
                        type='number'
                        value={Factura.porcentajedescuento}
                        setform={setFactura}
                        formulario={Factura}
                    />
                    <Inputform
                        titulo='Descuento en Pesos'
                        name='preciodescuento'
                        type='text'
                        value={`$${Factura.preciodescuento.toLocaleString('es-CL')}`}

                        Disabled={true}
                    />

                </div>
                <br />
           
                <div className='element-montos'>
                    <strong>{`Neto: ${(parseInt(Factura.valortotal|| 0)-Factura.valoriva).toLocaleString('es-CL') }`}</strong>
                    <strong>{`IVA: ${Factura.valoriva.toLocaleString('es-CL')} CLP`}</strong>
                    <strong>{`Total: ${Factura.valortotal.toLocaleString('es-CL')} CLP`}</strong>
                    <strong>{`Pendiente de Pago: ${parseInt(Factura.valortotal)-parseInt(Factura.pagado)} CLP`}</strong>
                </div>
                <br />
                <strong>Formas de Pago</strong>
                <hr />
                <div className='element-pago'><button onClick={handleshowpago} className='element-boton agregarpago' style={{borderRadius:'10px'}}>Agregar Pago</button></div>
                <div>
                    <table className='Tablaproducto'>
                        <thead>
                            <tr>
                                <td>Tipo de Pago</td>
                                <td>Monto Pagado</td>
                                <td>Fecha</td>
                                <td>Tipo Cheque</td>
                                <td>Eliminar</td>
                            </tr>
                        </thead>
                        <tbody>
                            {Factura?.metodosdepago?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.tipopago}</td>
                                    <td>${parseFloat(item.monto ||0).toLocaleString("es-CL")}</td>
                                    <td>{convertirFecha(item.fechacheque)}</td>
                                    <td>{item.tipocheque}</td>
                                    <td><img onClick={() => handleModaleliminar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div>
                    <span>Observacion</span>
                    <br />
                    <textarea onChange={handleitem} rows="5" cols="130" style={{ padding: "5px" }} defaultValue={""}>

                    </textarea>
                </div>
                <div className='element-subirdocumentos'>
                    {Factura.adjunto.Documento === "" ? <><span>Adjuntar Documento:</span>
                        <img style={{ cursor: 'pointer', margin: '5px 50px 5px 50px' }} onClick={handleModaldocumento} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1707758619/pwd8nqsfmzaxazd3s2sd.png" alt="imagen de subir" width={'40px'} /></>
                        : <><span>Adjuntar Documento: {Factura.adjunto.Documento} </span> &nbsp; <img onClick={handleadjunto} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1712525937/hlmyv9pradkhasmviblc.png" alt="icon-close" width={"20px"} height={"18px"} /></>}
                </div>
                <div><button className='element-boton generarfactura' onClick={handlecrearfactura}>Generar Factura</button>
                </div>
                <Modal showModal={Modalshowdocumento} closeModal={handleModaldocumento}>
                    <DragAndDropOrdenCompra
                        Adquisiciones={Factura}
                        setadquisiciones={setFactura}
                        handleModal={handleModaldocumento}
                        ordencompra={Factura.adjunto}
                        iditem={Math.floor(Date.now() / 1000)}
                    />
                </Modal>


                <Modal showModal={showFormadepago} closeModal={handleshowpago}>
                    <FormPago
                        handleModal={handleshowpago}
                        Factura={Factura}
                        setFactura={setFactura}
                    />
                </Modal>

                <Modal showModal={showeliminar} closeModal={handleModaleliminar}>
                    <div className='bloque-adv-eliminar'>

                        <strong>Seguro que deseas eliminar el item?</strong>
                        <br />
                        <br />
                        <div className='element-save-bar'>
                            <button className="element-boton Holder" onClick={handleModaleliminar}>Cerrar</button>
                            <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
                        </div>

                    </div>
                </Modal>
            </>}
        </div>
    )
}
