import React, { useEffect, useState } from 'react'
import { useGetAdquisiciones } from '../../Hooks/useGetAdquisiciones';

import "../../CSS/Seguimiento.css"
import { LoadingSpinner } from '../../Utility/Spiner';
import { TablaSeguimiento } from '../Components/TablaSeguimiento';
import { Pagination } from '../../ui/Pagination/components/pagination';

export const SeguimientoAdquisicion = () => {

    const [adquisiciones, setadquisiciones] = useState()
    const { data, loading } = useGetAdquisiciones();


    return (
        <>{loading ? (<LoadingSpinner/>) : (<div className='bloque-render'>
            <h2>Seguimiento de Adquisiciones</h2>
            <hr />
            <br />
            <TablaSeguimiento
                Adquisiciones={adquisiciones} 
                setadquisiciones={setadquisiciones}/>
                <Pagination elementos={data} setFilterdata={setadquisiciones}/>
        </div>)}</>
    )
}
