import React from 'react';


export const PendingPaymentsTable = () => {
  // Datos de ejemplo
  const pendingPayments = [
    { empresa: 'Empresa A', monto: 15000, fecha: '2023-05-15' },
    { empresa: 'Empresa B', monto: 30000, fecha: '2023-05-10' },
    { empresa: 'Empresa C', monto: 22000, fecha: '2023-05-05' },
    { empresa: 'Empresa D', monto: 50000, fecha: '2023-06-01' },
    { empresa: 'Empresa E', monto: 10000, fecha: '2023-07-20' },
    { empresa: 'Empresa F', monto: 70000, fecha: '2023-07-05' },
    { empresa: 'Empresa G', monto: 90000, fecha: '2023-08-30' },
    { empresa: 'Empresa H', monto: 12000, fecha: '2023-08-02' },
    { empresa: 'Empresa I', monto: 15000, fecha: '2023-09-25' },
    { empresa: 'Empresa J', monto: 11000, fecha: '2023-10-15' },
  ];

  return (
    <div style={{ width: '100%' }}>
      <h2>Pagos Pendientes</h2>
      <div style={{ maxHeight: '260px', overflowY: 'auto' }}>
        <table className='Tablaproducto' style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Empresa</th>
              <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Monto Pendiente</th>
              <th style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>Fecha Factura</th>
            </tr>
          </thead>
          <tbody>
            {pendingPayments.map((payment, index) => (
              <tr key={index}>
                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{payment.empresa}</td>
                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>${Number(payment.monto).toLocaleString()}</td>
                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{payment.fecha}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
