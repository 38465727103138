import React from 'react';

export const PendingShipmentsTable = () => {
  // Datos de ejemplo
  const pendingShipments = [
    { idPedido: '001', fechaDespacho: '2023-10-01', estado: 'En preparación' },
    { idPedido: '002', fechaDespacho: '2023-10-02', estado: 'Listo para enviar' },
    { idPedido: '003', fechaDespacho: '2023-10-03', estado: 'En tránsito' },
    { idPedido: '004', fechaDespacho: '2023-10-05', estado: 'Pendiente' },
    { idPedido: '005', fechaDespacho: '2023-10-10', estado: 'En preparación' },
  ];

  return (
    <div style={{ width: '100%' }}>
      <h2>Envíos Pendientes</h2>
      <div style={{ maxHeight: '260px', overflowY: 'auto' }}>
        <table className='Tablaproducto' style={{ width: '100%'}}>
          <thead>
            <tr>
              <th style={{ borderBottom: '1px solid #ddd' }}>ID Pedido</th>
              <th style={{ borderBottom: '1px solid #ddd' }}>Fecha de Despacho</th>
              <th style={{ borderBottom: '1px solid #ddd' }}>Estado Actual</th>
            </tr>
          </thead>
          <tbody>
            {pendingShipments.map((shipment, index) => (
              <tr key={index}>
                <td style={{  borderBottom: '1px solid #ddd' }}>{shipment.idPedido}</td>
                <td style={{  borderBottom: '1px solid #ddd' }}>{shipment.fechaDespacho}</td>
                <td style={{  borderBottom: '1px solid #ddd' }}>{shipment.estado}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
