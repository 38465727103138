import React from 'react'
import { useState } from 'react';
import { UpdateProveedor } from '../../Helpers/UpdateProveedor';
import Popup from '../../Utility/PopUpNotificacion';

let Inputform = ({ titulo, name, type, value, setform, formulario }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });

    };

    return (
        < >
            <label className='element-input'>

                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                />

            </label>
        </>)
}
const Selectform = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name }) => {

    const handleChange = (e) => {
        setOpcionSeleccionada({ ...opcionSeleccionada, [name]: e.target.value });
    };

    return (
        <div className='element-input'>
            <label htmlFor="miSelect" className='modifier-titulo'>{titulo}</label>
            <select className='modifier-input' id="miSelect" value={opcionSeleccionada.proveedor} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}

export const FormAgregarProductoProveedor = ({ handleModal, productos ,proveedor,setproveedor}) => {
    const [productoProveedor, setproductoProveedor] = useState({ productomedicpro: '', productoproveedor: '', descripcion: '', hidden: false })
    const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

    const handleSubmit = async(e) => {
        e.preventDefault()


        const productoBuscado = proveedor.Productos.find(producto => producto.productomedicpro === productoProveedor.productomedicpro);
     
        if (productoBuscado) {
            setShowPopup(true);
            
            setmsgpopup({ tipo: 'error', msg: "Producto ya existe en la lista" })
            
           return setTimeout(() => {
                setShowPopup(false);
            }, 4000);
        }

        let nuevoproducto ={...proveedor}
        nuevoproducto.Productos.push(productoProveedor)
        setproveedor(nuevoproducto)

        let res = await UpdateProveedor(nuevoproducto)
 
        setShowPopup(true);
        setmsgpopup({ tipo: 'exito', msg: "Producto agregado exitosamente" })
        if (!res.succes) {
             setmsgpopup({ tipo: 'error', msg: res.estado })
         }
         setTimeout(() => {
             setShowPopup(false);
         }, 4000);

         setproductoProveedor({ productomedicpro: '', productoproveedor: '', descripcion: '', hidden: false })
        return res.succes && handleModal();
       
    };

    return (
        <form onSubmit={handleSubmit}>
{showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
            <div style={{ padding: '10px' }}>
                <h4 style={{ paddingTop: '10px' }}>Agregar Producto Proveedor</h4>
                <hr />
                <br />

                <Selectform
                    opciones={productos}
                    name="productomedicpro"
                    titulo="Nombre Producto MedicPro"
                    opcionSeleccionada={productoProveedor}
                    setOpcionSeleccionada={setproductoProveedor}

                />

                <Inputform
                    titulo='Nombre Producto Proveedor'
                    name='productoproveedor'
                    type='text'
                    value={productoProveedor.productoproveedor}
                    setform={setproductoProveedor}
                    formulario={productoProveedor}
                />


                <Inputform
                    titulo='Descripción'
                    name='descripcion'
                    type='text'
                    value={productoProveedor.descripcion}
                    setform={setproductoProveedor}
                    formulario={productoProveedor}
                />

    
                <div className='element-save-bar'>
                    <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                    <button className="element-boton add" onClick={handleSubmit}>Agregar</button>
                </div>
                <br />
            </div>
        </form>
    )
}
