import React, { useEffect, useState } from 'react'
import { LoadingSpinner } from '../../Utility/Spiner';
import { TablaTipoVenta } from '../Components/TablaTipoVenta'

import { Modal } from '../../Utility/Modal';
import { useGetAction } from '../../Hooks/useGetAction'
import { FormAgregarTipoVenta } from '../Components/FormAgregarTipoVenta'


export const TipoVenta = () => {
    let { loading, data } = useGetAction('Conf/obtenertipoventas')
    const [TipoVenta, setTipoVenta] = useState([])
    const [showModal, setshowModal] = useState(false)

    useEffect(() => {
     if(!loading){
       
        setTipoVenta(data);
        
     }
    }, [data,loading])
    


    let handleModal = () => {
        setshowModal(!showModal)
    }

    return (
        <>

            {loading ? <LoadingSpinner /> : <div className='bloque-render'>
                <div style={{ display: "flex" }}><h2>Contenedor de Tipos de Venta</h2></div>
                <hr />
                <br />
                <button className='element-boton agregar' style={{width:'230px'}} onClick={handleModal}>Agregar Tipo de Venta</button>


                <TablaTipoVenta TipoVenta={TipoVenta} setTipoVenta={setTipoVenta}/>

                <Modal showModal={showModal} closeModal={handleModal} >
                    <FormAgregarTipoVenta handleModal={handleModal}/>
                </Modal>
            </div>}
        </>
    )
}
