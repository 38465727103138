import React, { useEffect, useState } from 'react'
import '../style/Usuario.css'
import { Modal } from '../../Utility/Modal';

import { useGetUsuarios } from '../../Hooks/useGetUsuarios';
import { LoadingSpinner } from '../../Utility/Spiner';
import { Buscador } from '../../Utility/Buscador';
import { TablaUsuarios } from '../Components/TablaUsuarios';
import { FormAgregarUsuario } from '../Components/FormAgregarUsuario';

export const GestionUsuarios = () => {


    const [usuarios, setusuarios] = useState([])

    let { data, loading } = useGetUsuarios()

    useEffect(() => {

        if (!loading) {
            setusuarios(data);
            setProductosFiltrados(data);
        }
        
    }, [data, loading]);

    const [filtroNombre, setFiltroNombre] = useState('');
    const [productosFiltrados, setProductosFiltrados] = useState([]);
    const [ShowModal, setShowModal] = useState(false)
    

    let handleModal = () => {
        setShowModal(!ShowModal)
    }
   
    return (
        <>
            {loading ? (<LoadingSpinner />)
                :
                <div className='bloque-render'>
                    <h2>Usuarios</h2>
                    <hr />
                    <br />
                    <Buscador
                        filtroNombre={filtroNombre}
                        setFiltroNombre={setFiltroNombre}
                        setProductosFiltrados={setProductosFiltrados}
                        Lista={usuarios}
                        placeholder='Buscar por RUT'
                    />
                    <div className='element-agregaruser'>
                        <button className='element-boton agregarusuario' style={{borderRadius:'10px'}} onClick={handleModal}>Agregar Usuario</button>
                    </div>
                    <TablaUsuarios usuarios={productosFiltrados} />
                    <Modal showModal={ShowModal} closeModal={handleModal}>
                        <FormAgregarUsuario
                            Usuario={usuarios}
                            setProductosFiltrados={setProductosFiltrados}
                            Setusuario={setusuarios}
                            handleModal={handleModal} />
                    </Modal>
                    
                </div>}
        </>


    )
}
