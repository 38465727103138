import React, { useState, useRef } from 'react';
import { UploadFile } from '../../Helpers/UploadFile';

export const DragAndDropOrdenCompra = ({ Adquisiciones, setadquisiciones, handleModal ,iditem=""}) => {

    
    const [dragging, setDragging] = useState(false);
    const [upload, setUpload] = useState(false);
    const [nameFile, setNameFile] = useState('');
    const fileInputRef = useRef(null);
  
    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        
        // Convertir los archivos del evento en una lista
        const files = Array.from(e.dataTransfer.files);
        
        if (files.length === 0) {
         
            return;
        }
       
        // Generar un nuevo nombre para el primer archivo
        const firstFile = files[0];
        const newFileName = Adquisiciones.ordencompra=== undefined?`${iditem}${firstFile.name}`:`${Adquisiciones.ordencompra}${firstFile.name}`;
        
        // Crear un nuevo archivo con el nuevo nombre
        const newFile = new File([firstFile], newFileName, { type: firstFile.type });
        
        // Actualizar el estado con el nuevo nombre del archivo
        setNameFile(newFileName);
        
        // Actualizar el estado de carga
        setUpload(!upload);
        
        // Subir los archivos con los nombres modificados
        UploadFile([newFile, ...files.slice(1)]);
       
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        
        let NewName =Adquisiciones.ordencompra=== undefined?`${iditem}${file.name}`:`${Adquisiciones.ordencompra}${file.name}`;
        setNameFile(NewName);
        const newFile = new File([file], NewName, { type: file.type });

        setUpload(true);
   
        UploadFile([newFile]);
    };

    const handleOpenFileDialog = () => {
        fileInputRef.current.click();
    };

    const handleSave = () => {
        const nuevasAdquisiciones = { ...Adquisiciones };
        nuevasAdquisiciones.adjunto.Url = nameFile;
        nuevasAdquisiciones.adjunto.Documento = nameFile;
        
        setadquisiciones(nuevasAdquisiciones);
        
        handleModal();
    };

    return (
        <>
            {upload ? (
                <div
                    style={{
                        border: `2px dashed 'gray'`,
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        width: '600px',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <img src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1707789865/scbd22bvij00rkiec25v.png" alt="Icono PDF" width={'100px'} />
                    <span>{nameFile}</span>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                        <button onClick={handleModal} className='element-boton cerrar'>Cerrar</button>
                        <button onClick={handleSave} className='element-boton guardar'>Guardar</button>
                    </div>
                </div>
            ) : (
                <div
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={handleOpenFileDialog}
                    style={{
                        border: `2px dashed ${dragging ? 'blue' : 'gray'}`,
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}
                >
                    <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileInputChange} />
                    <h2>Arrastra y suelta archivos aquí <br />o <br />haz clic para seleccionar un archivo</h2>
                </div>
            )}
        </>
    );
};
