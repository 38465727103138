import React, { useState } from 'react'
import { Login } from '../../Helpers/Login';
import { LoadingSpinner } from '../../Utility/Spiner';

import { useNavigate } from 'react-router-dom'
import Popup from '../../Utility/PopUpNotificacion';


let Inputform = ({ titulo, name, type, value, setform, formulario }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });
    };

    return (
        < >
            <label className='element-input-login'>
                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    autocomplete="off"
                />
            </label>
        </>)
}

export const FormLogin = () => {
    const navigate = useNavigate()

    const [showPopup, setShowPopup] = useState(false);
    const [login, setlogin] = useState({ user: '', password: '' })
    const [loading, setloading] = useState(false)

    let handleLogin = async (e) => {
        e.preventDefault()
        setloading(true)
        let res = await Login(login)
     
        if (res.succes === false) {
            setloading(false)
            setShowPopup(true);
            // Ocultar el popup después de 6 segundos
            setTimeout(() => {
              setShowPopup(false);
            }, 4000);
            return 0
        }
        sessionStorage.setItem('metadata', JSON.stringify({usuario:res.user,rut:res.rut}));
        sessionStorage.setItem('Permisos', JSON.stringify(res.cargo.permisos));

        setloading(false)
        return navigate('/Home', { replace: true })

    }

    return (
        <>
            {loading ? <LoadingSpinner /> : <div className='bloque-form'>
                {showPopup && <Popup tipo='error' message='Usuario y/o Contraseña no es valido,Intente nuevamente' />}
                <img src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1710632286/khrprawa0rzpjstxjiwu.jpg" alt="LogoMedicPro" width={'50%'} />

               <form className='bloque-form' action={handleLogin}>

               <Inputform
                    titulo='Usuario'
                    name='user'
                    type='text'
                    value={login.user}
                    setform={setlogin}
                    formulario={login}
                />
                <br />
                <Inputform
                    titulo='Contraseña'
                    name='password'
                    type='password'
                    value={login.password}
                    setform={setlogin}
                    formulario={login}
                />



                <button className="element-boton Login" onClick={handleLogin}>Iniciar Sesion</button>
               </form>

            </div>}
        </>
    )
}
