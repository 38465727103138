import React, { useEffect, useState } from 'react'

import "../../CSS/Despachos.css"
import { TablaDespachos } from '../Components/TablaDespachos';
import { useGetOrdenDeCompra } from '../../Hooks/useGetOrdenDeCompra';
export const Despachos = () => {
  const [Despachos, setDespachos] = useState([])
  let { data, loading } = useGetOrdenDeCompra()

  useEffect(() => {
      if (!loading) {
         


         // const arregloFiltrado = data.filter(objeto => objeto.estado === "Pendiente" || objeto.estado === "Pago Parcial");
        setDespachos(data);  
        
            
      }

  }, [loading]);
  return (
    <div className='bloque-render'>
    <h2>Despachos</h2>
    <hr />
    <br /> 
    
    <TablaDespachos Despachos={Despachos}/>
    </div>
  )
}
