import { useState, useEffect } from "react";

import { GetProveedores } from "../Helpers/GetProveedores";

export const useGetproveedores = (bdy) => {
    const [state, setState] = useState({
        data: [],
        loading: true
    });


  useEffect(() => {
    // Define una función asincrónica para llamar a la API y actualizar el estado
    const fetchData =  async() => {
      try {
        const datos =  await GetProveedores(bdy);
        setState({
          data: datos,
          loading: false,
        });
      } catch (error) {
        window.location.replace(`/paginaError`)
      }
    };
    fetchData();
  }, [bdy]);

  return state;
};