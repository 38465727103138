import React from 'react'
import { SalesChart } from '../Components/ventaschart'
import "../style/Home.css"
import { PendingPaymentsTable } from '../Components/pagospendientes'
import { PendingShipmentsTable } from '../Components/enviospendientes'

export const Home = () => {
  return (
    <div  style={{paddingLeft:"20px"}}>

      <div className='element-sellchart'>
      <SalesChart/>
      </div>
      <div className='block-ItemsPendientes'>
        <div className='card-envios'><PendingShipmentsTable/></div>
        <div className='card-pagos'><PendingPaymentsTable/></div>
      </div>
    </div>
  )
}
