import React, { useEffect, useState } from 'react';
import '../CSS/Flotante.css'; // Asegúrate de que la ruta al archivo CSS es correcta

const FloatingButton = ({setshowsidebar}) => {


    
    const [showsidebarf, setshowsidebarf] = useState(true)



    const [isSmallScreen, setIsSmallScreen] = useState(window.matchMedia('(max-width: 500px)').matches);

    useEffect(() => {
      const handleResize = (event) => {
        setIsSmallScreen(event.matches);
        // Actualiza showsidebar basado en el tamaño de pantalla
     
        setshowsidebarf(!event.matches); // Aquí es donde se cambia `showsidebar` a true o false
      };
  
      const mediaQueryList = window.matchMedia('(max-width: 900px)');
      mediaQueryList.addEventListener('change', handleResize);
  
      // Ejecutar el efecto inicial
      handleResize(mediaQueryList);
  
      return () => {
        mediaQueryList.removeEventListener('change', handleResize);
      };
    }, []);
let handlefloating=()=>{
    setshowsidebarf(!showsidebarf)
    setshowsidebar(!showsidebarf)
}
  return (
    <button style={showsidebarf?{left: "230px"}:{left:"-25px"}} className="floating-button" onClick={() => handlefloating()}>
      <img src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1705254404/dqvbyntcl9tuy7exunrk.png" alt="Arrow" width={'20px'} style={!showsidebarf?{transform:" rotate(270deg)" }:{transform:" rotate(90deg)" }}/>
    </button>
  );
};

export default FloatingButton;
