import React, { useEffect, useState } from 'react'
import '../style/Adquisicion.css'

import { useGetProductosProveedor } from '../../Hooks/useGetProductosProveedor'
import { LoadingSpinner } from '../../Utility/Spiner';
import { FormPedido } from '../Components/FormPedido';


export const Adquisicion = () => {
  const [Proveedores, setProveedores] = useState()

  let { data, loading } = useGetProductosProveedor()
  useEffect(() => {

    if (!loading) {
      setProveedores(data);

    }

  }, [data, loading]);


  const [formulario, setformulario] = useState({
    noperacion: "",
    fecha: "",
    vdolar: "",
    pi: "",
    opepesos: 0,
    opeusd: 0,
    swift: "",
    toperacion: "",
    banco: "",
    cae: "",
    abono: "",
    pabono: "",
    pabonousd: "",
    mcae: "",
    productos: [],
    pais: ""
  })


  return (
    <>
      {loading ? <LoadingSpinner /> : <div className='bloque-render'>
        <div style={{ display: "flex" }}><h2>Adquisiciones Comex</h2></div>
        <hr />
        <br />
        <FormPedido
          formulario={formulario}
          setform={setformulario}
          proveedoresList={Proveedores}
        />
      </div>}
    </>

  )
}
