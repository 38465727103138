import React, { useEffect, useState } from 'react'
import { useGetProductos } from '../../Hooks/useGetProductos';
import { CreateDespacho } from '../../Helpers/CreateDespacho';
import Popup from '../../Utility/PopUpNotificacion';

import { HeaderModal } from '../../Utility/HeaderModal'

export const FormDespacho = ({ handleModal, item }) => {

    const [msgpopup, setmsgpopup] = useState({ tipo: '', msg: '' })
    const [ShowPopup, setShowPopup] = useState(false)
    const [newItem, setnewItem] = useState([])
    const [producto, setproducto] = useState([])
    const [Despacho, setDespacho] = useState({ factura: item, productosenviados: [], observacion: "", documento: [], idFactura: '' })

    let { data, loading } = useGetProductos();

    useEffect(() => {
        if (!loading) {
            const objetoConStock = { ...item };
            // Iteramos sobre los productos del objeto principal
            objetoConStock.Productos.forEach(producto => {
                // Buscamos el producto correspondiente en el segundo objeto
                const productoEnSegundoObjeto = data.find(obj => obj._id === producto.nombre[0]._id);

                // Si encontramos el producto en el segundo objeto, agregamos el campo de stock al producto del objeto principal
                producto.cantidaddespacho = '';
                if (productoEnSegundoObjeto) {
                    producto.stockDisponible = productoEnSegundoObjeto.stockvirtual;
                } else {
                    producto.stockDisponible = "No disponible";
                }
            });
            setproducto(objetoConStock?.Productos || [])
            setnewItem(objetoConStock)

        }


    }, [data, loading, item])

    let handleCantidadDespacho = (value, index) => {
        let nuevoObjeto = { ...newItem }
        let productos_pendientes = parseInt(nuevoObjeto.Productos[index].cantidad) - parseInt(nuevoObjeto.Productos[index].cantidadDespachada || 0)

        if (parseInt(value || 0) <= productos_pendientes && parseInt(value || 0) > -1) {
            nuevoObjeto.Productos[index].cantidaddespacho = value
            setnewItem(nuevoObjeto)
        }
    };

    let handlefecha = (e) => {
        setDespacho({ ...Despacho, observacion: e.target.value })
    }


    let handlesubmit = async () => {
        let nuevoDespacho = { ...Despacho }
        nuevoDespacho.productosenviados = newItem.Productos

        if (nuevoDespacho.productosenviados.length > 0) {
            let res = await CreateDespacho(nuevoDespacho)

            if (res.succes === false) {

                setShowPopup(true);

                setTimeout(() => {
                    setShowPopup(false);
                }, 4000);
                setmsgpopup({ tipo: 'error', msg: "No se proceso el Despacho, Intentar Nuevamente" })
                return 0
            }
            setmsgpopup({ tipo: 'exito', msg: "Adquisicion registrada de forma exitosa" })
            setShowPopup(true);

            setTimeout(() => {
                setShowPopup(false);
            }, 3000);
            return res.succes && window.location.reload();
        }
    }

    return (
        <div>
            {ShowPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
            <br />
            <HeaderModal titulo={"Generar Despacho"} handleModal={handleModal} />
            <hr />
            <br />

            <div className='bloque-display-data'>
                <span>Cliente:{item.cliente.nombre}</span>
                <span>Orden de Compra:{item.ordencompra}</span>

                <div><span>Fecha Solicitud:</span> <input type='date' onChange={handlefecha} /></div>
                <div><span>Subir Documento:</span> </div>

            </div>
            <table className='Tablaproducto'>
                <thead>
                    <tr>
                        <td>Nombre Producto</td>
                        <td>Productos Venta</td>
                        <td>Productos Disponible</td>
                        <td>Stock Disponible</td>
                        <td>Productos a despachar</td>
                    </tr>
                </thead>
                <tbody>

                    {producto?.map((item, index) => (
                        <tr key={index}>
                            <td>{item.nombre[0].nombre}</td>
                            <td>{item.cantidad}</td>
                            <td>{parseInt(item.cantidad) - parseInt(item.cantidadDespachada || 0)}</td>
                            <td>{item.stockDisponible}</td>
                            <td><input type="number" placeholder='Cantidad a Despachar' value={item.cantidaddespacho} onChange={(e) => handleCantidadDespacho(e.target.value, index)} /></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div><button onClick={handlesubmit} className='element-boton agregar'>Generar Despacho</button></div>
        </div>
    )
}
