import React, { useState } from 'react'
import Popup from '../../Utility/PopUpNotificacion';
import { POSTAction } from '../../Helpers/POST';



let Inputform = ({ titulo, name, type, value, setform, formulario }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value });
    };

    return (
        < >
            <label className='element-input'>
                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                />
            </label>
        </>)
}

export const FormEditShipper = ({ handleModal,Shipper,setShipper}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })
    let handleeditarShipper = async() => {
        let res=await POSTAction(Shipper,'Conf/actualizarshipper')

        setShowPopup(true);
        setmsgpopup({ tipo: 'exito', msg: "Shipper actualizado de forma exitosa" })
        if (!res.succes) {
            setmsgpopup({ tipo: 'error', msg: res.estado })
        }
        setTimeout(() => {
            setShowPopup(false);
        }, 4000);
        return res.succes && window.location.reload();
    }

    return (
        <div>
            {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
            <h3 style={{ paddingTop: '10px' }}>Agregar Shipper</h3>
            <br />
            <hr />
            <br />
            <Inputform
                titulo='Nombre'
                name='nombre'
                type='text'
                value={Shipper.nombre}
                setform={setShipper}
                formulario={Shipper}
            />
            <Inputform
                titulo='Alias'
                name='alias'
                type='text'
                value={Shipper.alias}
                setform={setShipper}
                formulario={Shipper}
            />
            <Inputform
                titulo='Telefono'
                name='telefono'
                type='text'
                value={Shipper.telefono}
                setform={setShipper}
                formulario={Shipper}
            />
             <Inputform
                titulo='Correo'
                name='correo'
                type='text'
                value={Shipper.correo}
                setform={setShipper}
                formulario={Shipper}
            />
            
            
            <div className='element-save-bar'>
                <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                <button className="element-boton add" style={{width:'120px'}} onClick={handleeditarShipper}>Agregar</button>
            </div>
        </div>
    )
}
