import React, { useEffect, useState } from 'react'
import { Modal } from '../../Utility/Modal'
import { InputFormAdquisicion } from '../Section/inputFormAdquisicion';
let Inputform = ({ titulo, name, type, value, setform, formulario, Disabled = false }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "ValorDolar") {
           
        let value = e.target.value.replace(/[^\d,]/g, ''); // Elimina todo lo que no sea un número o coma decimal
        
        if (value) {
          // Reemplaza la coma por un punto decimal para poder trabajar con los decimales
          value = value.replace(',', '.');
        
          // Divide en parte entera y decimal
          const [integerPart, decimalPart] = value.split('.');
        
          // Formatea la parte entera (separando miles con puntos)
          let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        
          // Vuelve a juntar la parte entera formateada con la parte decimal si existe
          let formattedValue = decimalPart !== undefined 
            ? `${formattedIntegerPart},${decimalPart}` 
            : formattedIntegerPart;
        
            return setform({ ...formulario, [name]: formattedValue, pabonousd: formulario.opeusd * (value / 100), pabono: formulario.opepesos * (value / 100) })
        } else {
            setform({ ...formulario, [name]: '' }); // Si no hay valor, limpia el campo
        }
           
            // return setform({ ...formulario, [name]: value, pabonousd: formulario.opeusd * (value / 100), pabono: formulario.opepesos * (value / 100) })
        }
        setform({ ...formulario, [name]: value, pabonousd: formulario.opeusd * (formulario.abono / 100), pabono: formulario.opepesos * (formulario.abono / 100) });

    };

    return (
        < >
            <label className='element-input-adquisicion'>

                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={Disabled}
                    style={{ width: "630px" }}
                />

            </label>
        </>)
}
const Selectform = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name, setproductos, Productos }) => {

    const handleChange = (e) => {
        if (name === "Proveedor") {

            const nombresProductosAlgotec = Productos.find(proveedor => proveedor.proveedor === e.target.value)?.producto.map(producto => producto.nombre) || [];

            const productosObjeto = nombresProductosAlgotec.reduce((obj, nombre, index) => {
                obj[nombre] = nombre;
                return obj;
            }, {});
            setproductos(productosObjeto)
        }
        setOpcionSeleccionada({ ...opcionSeleccionada, [name]: e.target.value });
    };

    return (
        <div className='element-input-adquisicion'>
            <label htmlFor="miSelect" className='modifier-titulo'>{titulo}&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <select className='modifier-input-select-adquisicion' id="miSelect" value={opcionSeleccionada.proveedor} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}
export const FormAddProducto = ({ Proveedor, formulario, setform, ProveedorSeleccionado = '' }) => {

    const [opcionSeleccionada, setOpcionSeleccionada] = useState({ Proveedor: "", Producto: "", Cantidad: "", ValorDolar: "" });
    const [showModal, setShowModal] = useState(false);
    const [productos, setproductos] = useState([])

    useEffect(() => {

        const productos = Proveedor?.find(objeto => objeto.nombre === ProveedorSeleccionado);
        const productosFormateados = productos?.Productos?.reduce((acumulador, producto) => {
            acumulador[producto.productomedicpro] = producto.productomedicpro;
            return acumulador;
        }, {});

        if (productosFormateados !== undefined) {

            setproductos(productosFormateados)
            setOpcionSeleccionada({ ...opcionSeleccionada, Proveedor: ProveedorSeleccionado })

        }

    }, [ProveedorSeleccionado])

    const handleModal = (e) => {
        e.preventDefault()
        setShowModal(!showModal);
    };


    const handleadd = (e) => {
        e.preventDefault()
        let validacionexistencia = formulario.productos.find(item => item.Producto === opcionSeleccionada.Producto)
        console.log(formulario.productos);
        if (validacionexistencia) {
            return window.alert("Producto ya existe en la lista")
        }
        let operaciondolar = formulario.opeusd + (opcionSeleccionada.ValorDolar)
        let operacionpesos = operaciondolar * formulario.vdolar
        setform(prevState => ({
            ...prevState,
            productos: [...prevState.productos, { ...opcionSeleccionada, }], opeusd: operaciondolar, opepesos: operacionpesos
        }));

        setOpcionSeleccionada({ Proveedor: ProveedorSeleccionado, Producto: "", Cantidad: "", ValorDolar: "" })
        setShowModal(!showModal);
    }
   
    return (
        <div className='bloque-agregarproducto'>
            <div className='elment-boton-agregarproducto'>

                <button className="element-boton agregarproducto" onClick={handleModal}>Agregar Productos</button>
            </div>

            <Modal showModal={showModal} closeModal={handleModal}>
                <div style={{ width: "600px" }}>

                    <h3>Agregar Proveedor y Producto</h3>
                    <br />
                    <hr />
                    {/* <Selectform
                        // opciones={proveedoresObjeto}
                        opciones={proveedores}
                        name="Proveedor"
                        titulo="Proveedor"
                        opcionSeleccionada={opcionSeleccionada}
                        setOpcionSeleccionada={setOpcionSeleccionada}
                        setproductos={setproductos}
                        Productos={Proveedor}
                    /> */}
                    <br />
                    <Selectform
                        name="Producto"
                        opciones={productos}
                        titulo="Producto"
                        opcionSeleccionada={opcionSeleccionada}
                        setOpcionSeleccionada={setOpcionSeleccionada} />


                    <br />
                    <Inputform
                        titulo='Cantidad'
                        name='Cantidad'
                        type='Number'
                        value={opcionSeleccionada.Cantidad.toLocaleString('es-CL')}
                        setform={setOpcionSeleccionada}
                        formulario={opcionSeleccionada}
                    />
                    <br />
               
                    <Inputform
                        titulo='Monto Dolar'
                        name='ValorDolar'
                        type='Number'
                        value={opcionSeleccionada.ValorDolar.toLocaleString('es-CL')}
                        setform={setOpcionSeleccionada}
                        formulario={opcionSeleccionada}
                    />
                    <div className='bloque-acciones'>
                        <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                        <button className="element-boton agregar" onClick={handleadd}>Agregar</button>

                    </div>
                    <br />

                </div>
            </Modal>
        </div>
    )
}
