import React, { useState } from 'react'
import { useEffect } from 'react';

import "../../CSS/ListaProductos.css"
import { useGetProductos } from '../../Hooks/useGetProductos';
import { Buscador } from '../../Utility/Buscador';
import { LoadingSpinner } from '../../Utility/Spiner';
import { TablaProductos } from '../Components/ListaProductos';
export const ListaProductos = () => {

  const [productos, setProductos] = useState();

  const [filtroNombre, setFiltroNombre] = useState('');
  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const { data, loading } = useGetProductos();

  useEffect(() => {

    if (!loading) {
      setProductos(data);
      setProductosFiltrados(data);
    }
  }, [data, loading]);

  return (
    <>{loading ? <LoadingSpinner/>:<div className='bloque-render'>
    <h2>Lista de Productos</h2>
    <hr />
    <br />
    <Buscador
      filtroNombre={filtroNombre}
      setFiltroNombre={setFiltroNombre}
      setProductosFiltrados={setProductosFiltrados}
      Lista={productos}
      placeholder='Buscar por Nombre de producto'
      Campo='nombre'
    />
    <TablaProductos
      Productos={productosFiltrados} />
  </div>}</>
    
  )
}
