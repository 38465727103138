import { useEffect, useState } from "react";
import "../style/Pagination.css"
export const Pagination = ({elementos = [],setFilterdata}) => {
  const [paginaActual, setPaginaActual] = useState(1);

  const elementosPorPagina = 1;
  const totalPaginas = Math.ceil(elementos.length / elementosPorPagina);

  useEffect(() => {
    const inicio = (paginaActual - 1) * elementosPorPagina;
    setFilterdata(elementos.slice(inicio, inicio + elementosPorPagina));
  }, [paginaActual])
  

 
  const cambiarPagina = (numeroPagina) => {
    if (numeroPagina >= 1 && numeroPagina <= totalPaginas) {
      setPaginaActual(numeroPagina);
    }
  };

  return (
    <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
      <div style={{display:"flex", flexDirection:"row",alignContent:'center',justifyContent:'center',height:'30px'}}>
        <button className="button-prev"

          onClick={() => cambiarPagina(paginaActual - 1)}
          disabled={paginaActual === 1}
        ><img  src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1729599186/qgibf23csdiodsos1ca4.png" width={"30px"} alt="" className="img icon" style={{padding:'0',margin:'0'}}/>
        </button>
        <div style={{display:"flex", flexDirection:"row" ,width:'110px',margin:'0',padding:'0'}}>
        <span> {paginaActual} de {totalPaginas}</span>
        </div>
        <button
      className="button-next"
          onClick={() => cambiarPagina(paginaActual + 1)}
          disabled={paginaActual === totalPaginas}
        >
            <img  src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1729599186/nwxdqtfwxdcevhxgxmti.png" width={"30px"} alt="" className="img icon" style={{padding:'0',margin:'0'}}/>
        </button>
      </div>
    </div>
  );
};
