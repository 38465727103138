import React, { useEffect, useState } from 'react'

import { TablaProductosShop } from './TablaProductosShop'

import { FormAddProducto } from './FormAddProducto';
import { SendCreacion } from '../../Helpers/SendCreacion';

import Popup from '../../Utility/PopUpNotificacion';
import { LoadingSpinner } from '../../Utility/Spiner';
import { useGetAction } from '../../Hooks/useGetAction';
import { InputFormAdquisicion } from '../Section/inputFormAdquisicion';

let Inputform = ({ titulo, name, type, value, setform, formulario, Disabled = false }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "abono") {
            return setform({ ...formulario, [name]: value, pabonousd: formulario.opeusd * (value / 100), pabono: formulario.opepesos * (value / 100) })
        }
        setform({ ...formulario, [name]: value, pabonousd: formulario.opeusd * (formulario.abono / 100), pabono: formulario.opepesos * (formulario.abono / 100) });

    };

    return (
        < >
            <label className='element-input'>

                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                    disabled={Disabled}
                />

            </label>
        </>)
}
const Selectform = ({ opciones, titulo, opcionSeleccionada, setOpcionSeleccionada, name, setproductos, Productos }) => {

    const handleChange = (e) => {
        setOpcionSeleccionada({ ...opcionSeleccionada, [name]: e.target.value });
    };

    return (
        <div className='element-input'>
            <label htmlFor="miSelect" className='modifier-titulo'>{titulo}&nbsp;&nbsp;&nbsp;&nbsp;</label>
            <select className='modifier-input' id="miSelect" value={opcionSeleccionada.proveedor} onChange={handleChange}>
                <option value="" >
                    Selecciona una opción
                </option>
                {Object.keys(opciones).map((key) => (
                    <option key={key} value={key}>
                        {opciones[key]}
                    </option>
                ))}
            </select>
        </div>
    );
}

export const FormPedido = ({ formulario, setform, proveedoresList }) => {
    const [estado, setestado] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

    //---------------
    const [proveedores, setproveedores] = useState()
    const [opcionSeleccionada, setOpcionSeleccionada] = useState({ Proveedor: "", Producto: "", Cantidad: "", ValorDolar: "" });


    let { loading, data } = useGetAction('Conf/obtenerPA')
    const [Paises, setPaises] = useState([])

    useEffect(() => {
     if(!loading){
        const transformedObject =data.reduce((acc, item) => {
            // Convertir 'nombre' a minúsculas y eliminar caracteres no alfanuméricos para usarlo como clave
            const key = item.nombre.toLowerCase().replace(/[^a-z0-9]/gi, '');
        
            // Convertir la primera letra a mayúsculas para usarla como valor
            const value = item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase();
        
            acc[key] = value;
            return acc;
        }, {});
        setPaises(transformedObject);
        
     }
    }, [data,loading])

    let Banco = useGetAction('Conf/obtenerbancos')
    const [Bancos, setBancos] = useState([])
  

    useEffect(() => {
     if(!Banco?.loading){
        const transformedObject = Banco?.data?.reduce((acc, item) => {
            // Convertir 'nombre' a minúsculas y eliminar caracteres no alfanuméricos para usarlo como clave
            const key = item.nombre.toLowerCase().replace(/[^a-z0-9]/gi, '');
        
            // Convertir la primera letra a mayúsculas para usarla como valor
            const value = item.nombre.charAt(0).toUpperCase() + item.nombre.slice(1).toLowerCase();
        
            acc[key] = value;
            return acc;
        }, {});
        setBancos(transformedObject);
        
     }
    }, [Banco.data,Banco.loading])

    useEffect(() => {

        const proveedores = proveedoresList?.map(proveedor => proveedor.nombre);
        const proveedoresObjeto = proveedores?.reduce((obj, nombre) => {
            obj[nombre] = nombre;
            return obj;
        }, {});

        if (proveedoresObjeto !== undefined) {
            setproveedores(proveedoresObjeto)
            setestado(true)
        }



    }, [proveedoresList])

    //---------------

    useEffect(() => {
        //Seteo de Formulario copia
        let nuevoForm = { ...formulario }

        let total_dolares = formulario.productos.reduce((acc, producto) => {
            return acc + (parseFloat(producto.ValorDolar));
        }, 0);

        //se agrega el costo operacion en valor pesos (se toma en cuenta el cae)


        let conversionPesos = (total_dolares * parseFloat(nuevoForm.vdolar.replace(',','.')));
        nuevoForm.opepesos = conversionPesos
        //se agrega costo de la operacion (se toma en cuenta el cae)
        nuevoForm.opeusd = total_dolares
        //Se agrega calculo de Monto Abono USD
        nuevoForm.pabonousd = (nuevoForm.opeusd * (nuevoForm.abono / 100))
        //Se agrega calculo de Monto Abono
        nuevoForm.pabono = (nuevoForm.opepesos * (nuevoForm.abono / 100))
        //Se agrega calculo del Monto cae
      
        setform(nuevoForm)

    }, [formulario.productos, formulario.vdolar, formulario.pabono, formulario.abono, formulario.cae])

    useEffect(() => {
        if (formulario.abono > 100) {
            return setform({ ...formulario, abono: 100 })
        }
        if (formulario.cae > 100) {
            return setform({ ...formulario, cae: 100 })
        }
    }, [formulario.abono, formulario.cae])

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(  
          
            !formulario.abono);
        
        if (!formulario.noperacion ||
            !formulario.fecha ||
            !formulario.vdolar ||
            !formulario.pi ||
            !formulario.swift ||
            !formulario.toperacion ||
            !formulario.banco ||
            !formulario.abono ||
            !formulario.pabono ||
            !formulario.pabonousd ||
            formulario.productos.length === 0) {
            alert("Por favor complete todos los campos.");
            return;
        }

        let res = await SendCreacion(formulario)
        setShowPopup(true);
        setmsgpopup({ tipo: 'exito', msg: "Adquisicion registrada de forma exitosa" })
        if (res.succes === false) {
            setmsgpopup({ tipo: 'error', msg: res.estado })
        }
        setTimeout(() => {
            setShowPopup(false);
        }, 4000);
        return res.succes && window.location.replace(`/Seguimiento-Pedidos`)
    };

    const handlenumberinput =(e)=>{
        const { name } = e.target;
        let value = e.target.value.replace(/[^\d,]/g, ''); // Elimina todo lo que no sea un número o coma decimal
        
        if (value) {
          // Reemplaza la coma por un punto decimal para poder trabajar con los decimales
          value = value.replace(',', '.');
        
          // Divide en parte entera y decimal
          const [integerPart, decimalPart] = value.split('.');
        
          // Formatea la parte entera (separando miles con puntos)
          let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        
          // Vuelve a juntar la parte entera formateada con la parte decimal si existe
          let formattedValue = decimalPart !== undefined 
            ? `${formattedIntegerPart},${decimalPart}` 
            : formattedIntegerPart;
        
          setform({
            ...formulario,
            [name]: formattedValue,
            pabonousd: formulario.opeusd * (formulario.abono / 100),
            pabono: formulario.opepesos * (formulario.abono / 100),
          });
        } else {
          setform({ ...formulario, [name]: '' }); // Si no hay valor, limpia el campo
        }
        
        
    }


    return (
        <>{loading?<LoadingSpinner/>:<div>
        {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
        <div className='bloque-inputform'>
            <Selectform
                // opciones={proveedoresObjeto}
                opciones={estado ? proveedores : {}}
                name="Proveedor"
                titulo="Nombre Proveedor"
                opcionSeleccionada={opcionSeleccionada}
                setOpcionSeleccionada={setOpcionSeleccionada}
           
                Productos={proveedoresList}
            />
            <Selectform
                // opciones={proveedoresObjeto}
                opciones={Paises}

                name='pais'
                titulo='Pais Origen'
                opcionSeleccionada={formulario}
                setOpcionSeleccionada={setform}

            />
            <Inputform
                titulo='Número de Operación'
                name='noperacion'
                type='text'
                value={formulario.noperacion}
                setform={setform}
                formulario={formulario}
            />

            <Inputform
                titulo='PI'
                name='pi'
                type='text'
                value={formulario.pi}
                setform={setform}
                formulario={formulario}
            />


            <Inputform
                titulo='SWIFT 1'
                name='swift'
                type='text'
                value={formulario.swift}
                setform={setform}
                formulario={formulario}
            />
            <Inputform
                titulo='Fecha inicio producción'
                name='fecha'
                type='date'
                value={formulario.fecha}
                setform={setform}
                formulario={formulario}
            />
            <Selectform
                opciones={{ Full: "Full (F)", Complementaria: "Consolidado (C)" }}
                name='toperacion'
                titulo='Tipo Operación'
                opcionSeleccionada={formulario}
                setOpcionSeleccionada={setform}

            /><p></p>
            <Inputform
                titulo='Porcentaje de Abono'
                name='abono'
                type='number'
                value={formulario.abono}
                setform={setform}
                formulario={formulario}
            /><p></p>

            <Inputform
                titulo='Costo % Dolares'
                type='text'
                value={`USD ${parseInt(formulario.pabonousd).toLocaleString('es-CL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
                Disabled={true}
            />
            <Inputform
                titulo='Costo % Pesos'
                type='text'
                value={`$${formulario.pabono.toLocaleString('es-CL', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`}
                Disabled={true}
            />

        </div>

        <div className='modifier-separador'><span >Abono inicial</span></div>

        <div className='bloque-porcentajes'>
            <InputFormAdquisicion
                titulo='Valor Dólar'
                name='vdolar'
                type='number'
                value={formulario.vdolar.toLocaleString('es-CL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                change={handlenumberinput}
            /> <p></p> <p></p>
            <Selectform
                opciones={Bancos}
                name="banco"
                titulo="Banco"
                opcionSeleccionada={formulario}
                setOpcionSeleccionada={setform}

            />
            {/* <Inputform
                titulo='Comisión Banco USD'
                name='cae'
                type='text'
                value={parseInt(formulario.cae||0).toLocaleString("es-CL")}
                setform={setform}
                formulario={formulario}
            /> */}
            {/* <Inputform
                titulo='Costo comisión'
                name='mcae'
                type='number'
                value={formulario.mcae}
                setform={setform}
                formulario={formulario}
               
            /> */}
             <InputFormAdquisicion
                titulo='Comisión Banco'
                name='mcae'
                type='number'
                value={formulario.mcae.toLocaleString('es-CL', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                change={handlenumberinput}
            />
         
        </div>

        <div className='modifier-separador'><span >Lista de Productos</span></div>

        <div className='Bloque-Manejo Productos'>
            <FormAddProducto
                Selectform={Selectform}
                Inputform={Inputform}
                Proveedor={proveedoresList}
                formulario={formulario}
                setform={setform}
                ProveedorSeleccionado={opcionSeleccionada.Proveedor} />
            <TablaProductosShop
                form={formulario}
                setform={setform}
            />
        </div>

        <div className='element-generarcompra'><button className='element-boton agregarproducto' onClick={handleSubmit} >Generar Compra</button></div>
    </div>
}</>

    )
}
