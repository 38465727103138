import axios from "axios";
import { url } from "../Url";
export const GetAction = async (uri) => {

  const URL = `${url}/${uri}`;

  try {
    const response = await axios.get(URL);
    
    return await response.data;
   
  } catch (error) {
   
    return window.location.replace(`/paginaError`)
  }
};


