import React, { useEffect, useState } from 'react';


import { LoadingSpinner } from '../../Utility/Spiner';
import { POSTAction } from '../../Helpers/POST';
import { HeaderModal } from '../../Utility/HeaderModal';


let CheckboxInput = ({ titulo, isChecked, onChange, header = false }) => (
    <label style={header ? { fontWeight: '800' } : null}>
        {titulo} &nbsp;
        <input type="checkbox" value={titulo} checked={isChecked} onChange={onChange} />
    </label>
);
let Inputform = ({ titulo, name, type, value, setform, formulario }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setform({ ...formulario, [name]: value});

    };

    return (
        < >
            <label className='element-input'>

                <div className='modifier-titulo'>{titulo}</div>
                <input className='modifier-input'
                    type={type}
                    name={name}
                    value={value}
                    onChange={handleChange}
                />

            </label>
        </>)
}
export const FormEditarPermisos = ({data, loading, handleModal,predata,nombre }) => {
   
    const [permisos, setPermisos] = useState();
    const [cargo, setcargo] = useState({ nombrecargo: nombre, permisos: [] ,hidden:false})


    useEffect(() => {
        if (!loading && data) {
            const transformedArray = data.map(mappingItem => {
                const originalPermiso = predata.permisos.find(permiso => permiso.name === mappingItem.name) || { items: [] };
                const itemsChecked = mappingItem.items.reduce((acc, item) => {
                  acc[item] = originalPermiso.items.includes(item);
                  return acc;
                }, {});
              
                return {
                  name: mappingItem.name,
                  icon: mappingItem.icon,
                  items: mappingItem.items,
                  isChecked: originalPermiso.items.length > 0,
                  itemsChecked: itemsChecked
                };
              });
              
           
            
            setPermisos(transformedArray);
        }
    }, [data, loading]);

    const handleHeaderCheckboxChange = (titulo, isChecked) => {
        const updatedPermisos = permisos.map(permiso => {
            if (permiso.name === titulo) {
                const updatedItemsChecked = {};
                for (const item of permiso.items) {
                    updatedItemsChecked[item] = isChecked;
                }
                return { ...permiso, isChecked, itemsChecked: updatedItemsChecked };
            }
            return permiso;
        });
       
        setPermisos(updatedPermisos);
    };

    const handleCheckboxChange = (titulo, isChecked) => {
        const updatedPermisos = permisos.map(permiso => {
            if (permiso.items.includes(titulo)) {
                return {
                    ...permiso,
                    itemsChecked: {
                        ...permiso.itemsChecked,
                        [titulo]: isChecked
                    }
                };
            }
            return permiso;
        });
     
        setPermisos(updatedPermisos);
        
    };

    let CrearCargo=async ()=>{
        const nuevoFormato = [
          
        ];
        
        permisos.forEach(item => {
            if (true) {
                const newItem = {
                    name: item.name,
                    icon: item.icon,
                    items: []
                };
        
                for (const itemName in item.itemsChecked) {
                    if (item.itemsChecked[itemName]) {
                        newItem.items.push(itemName);
                    }
                }
        
                nuevoFormato.push(newItem);
            }
        });
        
   
        await POSTAction({...cargo,permisos:nuevoFormato,"_id":predata._id},"usuarios/actualizarpermiso")
        
        return window.location.reload(true)
    }

    return (
        <>
            {loading ? (
                <LoadingSpinner />
            ) : (

                <div style={{ padding: '10px', width: '500px' }}>
                      <HeaderModal titulo='Actualizar Cargo' handleModal={handleModal}/>
                    <br />
                    <hr />
                    <br />

                    <Inputform titulo='Nombre Cargo'
                        name='nombrecargo'
                        type='text'
                        value={cargo.nombrecargo}
                        formulario={cargo}
                        setform={setcargo}
                    />
                    <br />
                    <strong style={{paddingLeft:'40px'}}>Seleccion de Permisos</strong>
                    <br />
                    <br />
                    {permisos?.map((permiso, index) => (
                        <div key={index} style={{ padding: '10px', paddingLeft: '40px' }}>
                            <CheckboxInput
                                titulo={permiso.name}
                                isChecked={permiso.isChecked}
                                onChange={(e) => handleHeaderCheckboxChange(permiso.name, e.target.checked)}
                                header={true}
                            />
                            <div>
                                {permiso.items.map((pagina, indexchild) => (
                                    <div key={`child${indexchild}`} style={{ padding: '10px', paddingLeft: '20px' }}>
                                        <CheckboxInput
                                            titulo={pagina}
                                            isChecked={permiso.itemsChecked[pagina]}
                                            onChange={(e) => handleCheckboxChange(pagina, e.target.checked)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <br />
       
            <div className='element-save-bar'>
                <button className="element-boton cerrar" onClick={handleModal}>Cerrar</button>
                <button className="element-boton agregar" style={{width:'130px'}} onClick={CrearCargo}>Actualizar</button>
            </div>
        </>
    );
};
