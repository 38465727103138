import React, { useState, useEffect, useRef } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';

const BarcodeScanner = ({ result, setresult, searchqr }) => {
  const [scanning, setScanning] = useState(false);
  const [lastResult, setLastResult] = useState(null); // Estado para el último resultado
  const videoRef = useRef(null);
  const codeReader = useRef(new BrowserMultiFormatReader());

  useEffect(() => {
    let scanInterval;

    if (scanning) {
      const startScanning = () => {
        if (videoRef.current) {
          codeReader.current.decodeFromVideoDevice(null, videoRef.current, (result, error) => {
            if (result) {
              // Solo actualiza si el resultado es diferente del último resultado
              if (result.text !== lastResult) {
                setresult(result.text);
                searchqr(result.text);
                setLastResult(result.text); // Actualiza el último resultado
              }
              setScanning(false);
            }
          }).catch((err) => console.error(err));
        }
      };

      startScanning();

      // Opcionalmente, puedes usar un mecanismo de polling para escanear continuamente
      scanInterval = setInterval(() => {
        startScanning();
      }, 30000); // Ajusta el intervalo según sea necesario

    } else {
      clearInterval(scanInterval);
    }

    // Cleanup on component unmount
    return () => {
      clearInterval(scanInterval);
      if (videoRef.current) {
        const stream = videoRef.current.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
        }
        videoRef.current.srcObject = null;
      }
    };
  }, [scanning, lastResult]);

  const toggleScanning = () => {
    setScanning(!scanning);
  };

  return (
    <div>
      <button className='element-boton escanear' onClick={toggleScanning}>
        {scanning ? 'Parar Scanner' : 'Iniciar Scanner'}
      </button>

      {scanning && (
        <video
          ref={videoRef}
          style={{ width: '100%', height: 'auto' }}
          autoPlay
        />
      )}

      {result && <p>Result: {result}</p>}
    </div>
  );
};

export default BarcodeScanner;
