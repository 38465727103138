import axios from "axios";
import { url } from "../Url";
export const createFactura = async (Body) => {

  const Header ={
    mode: "no-cors",
  }
 
  const URL = `${url}/despachosyfacturas/crearfactura`;

  try {
    const response = await axios.post(URL, Body, Header);
 
    return await response.data;
   
  } catch (error) {
   console.log(error);
    return window.location.replace(`/paginaError`)
  }
};


