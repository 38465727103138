import axios from "axios";
import { url } from "../Url";
export const GetStock = async (producto) => {
  const URL = `${url}/GADM/obtenerstock/${producto}`; 
  try {
    const response = await axios.get(URL);
    return await response.data;
  } catch (error) {
   
    return window.location.replace(`/paginaError`)
  }
};


