import React from 'react'

export const HeaderModal = ({titulo,handleModal}) => {
  return (
    <div className='element-filter-bar'><h3>{titulo}</h3>
    <img onClick={handleModal} src="https://res.cloudinary.com/dgi90lgbq/image/upload/v1712525937/hlmyv9pradkhasmviblc.png" alt="icon-close" width={"20px"} height={"18px"} style={{cursor:'pointer'}} />
    
</div>
  )
}
