import React from 'react'

export const Buscador = ({filtroNombre,setFiltroNombre,setProductosFiltrados,Lista,placeholder,Campo='rut'}) => {
    const filtrarPorNombre = (nombre) => {
        const productosFiltrados = Lista.filter(producto =>
            producto[Campo].toLowerCase().includes(nombre.toLowerCase())
        );
        setProductosFiltrados(productosFiltrados);
    };
  return (
    <><div className='bloque-filtro-productos'>
    <label className='element-input'>
        <div className='modifier-buscar'>Buscar </div>
        <input className='modifier-input'
            type="text"
            name="buscar"
            value={filtroNombre}
            placeholder={placeholder}
            onChange={(e) => {
                setFiltroNombre(e.target.value);
                filtrarPorNombre(e.target.value);
            }}
        />
    </label>
</div></>
  )
}
