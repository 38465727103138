import { useState, useEffect } from "react";
import { GetClientes } from "../Helpers/GetClientes";

export const useGetClientes = () => {
    const [state, setState] = useState({
        data: [],
        loading: true
    });


  useEffect(() => {
    // Define una función asincrónica para llamar a la API y actualizar el estado
    const fetchData =  async() => {
      try {
        const datos =  await GetClientes();
        setState({
          data: datos,
          loading: false,
        });
      } catch (error) {
        window.location.replace(`/paginaError`)
      }
    };
    fetchData();
  }, []);

  return state;
};