import React, { useState } from 'react'
import { Modal } from '../../Utility/Modal';
import { FormEditShipper } from './FormEditShipper'
import { POSTAction } from '../../Helpers/POST'
import Popup from '../../Utility/PopUpNotificacion';




export const TablaShipper = ({ Shipper ,setShipper}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })

  const [showeliminar, setshoweliminar] = useState(false)
  const [showmodificar, setshowmodificar] = useState(false)
  const [item, setitem] = useState([])
  const [Posicion, setPosicion] = useState('')

  let handleModaleliminar = (index) => {
    setshoweliminar(!showeliminar)
    setPosicion(index)
  }
  let handleModalModificar = (index) => {
    setitem(Shipper[index])
    setshowmodificar(!showmodificar)


  }
  let handleeliminar = async () => {
   
    //let res=({succes:true})
    let res=await POSTAction(Shipper[Posicion],'Conf/eliminarshipper')

    setShowPopup(true);
    setmsgpopup({ tipo: 'exito', msg: "Shipper eliminado de forma exitosa" })
    if (!res.succes) {
        setmsgpopup({ tipo: 'error', msg: res.estado })
    }
    setTimeout(() => {
        setShowPopup(false);
    }, 4000);

    let newArray = [...Shipper];
    newArray.splice(Posicion, 1);
    setShipper(newArray);
    
    return res.succes && setshoweliminar(!showeliminar)
  }

  return (
    <>
    {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
      <table className='Tablaproducto'>
        <thead><tr>
          <td>Nombre Shipper</td>
          <td>Alias</td>
          <td>Telefono</td>
          <td>Correo</td>
          <td>Modificar</td>
          <td>Eliminar</td>
        </tr></thead>
        <tbody>
          {Shipper?.map((item, index) => (
            <tr key={index}>
              <td>{item.nombre}</td>
              <td>{item.alias}</td>
              <td>{item.telefono}</td>
              <td>{item.correo}</td>
              <td><img onClick={() => handleModalModificar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/uvowjb2taevrvvb2qcdq.png' alt='logo modificar' width={"25px"} style={{ cursor: 'pointer' }} /></td>
              <td><img onClick={() => handleModaleliminar(index)} src='https://res.cloudinary.com/dgi90lgbq/image/upload/v1707088600/npprklizofvutpbdflwz.png' alt='logo eliminar' width={"25px"} style={{ cursor: 'pointer' }} /></td>

            </tr>
          ))}
        </tbody>

      </table>
      <Modal showModal={showeliminar} closeModal={handleModaleliminar}>
        <div className='bloque-adv-eliminar'>

          <strong>Seguro que deseas eliminar el item?</strong>
          <br />
          <br />
          <div className='element-save-bar'>
            <button className="element-boton Holder" onClick={handleModaleliminar}>Cerrar</button>
            <button className="element-boton cerrar" onClick={handleeliminar}>Eliminar</button>
          </div>

        </div>
      </Modal>
      <Modal showModal={showmodificar} closeModal={handleModalModificar}>
        <FormEditShipper handleModal={()=>setshowmodificar(!showmodificar)} Shipper={item} setShipper={setitem}/> 
      </Modal> 
    </>
  )
}
