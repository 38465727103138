import React, { useEffect, useState } from 'react'
import Popup from '../../Utility/PopUpNotificacion';
import { useGetAction } from '../../Hooks/useGetAction';
import { POSTAction } from '../../Helpers/POST';
import { sleep } from '../../Utility/sleep';
import { HeaderModal } from '../../Utility/HeaderModal';

export const AsignacionChofer = ({ item, handleModal ,setitem}) => {


  const [showPopup, setShowPopup] = useState(false);
  const [msgpopup, setmsgpopup] = useState({ tipo: 'error', msg: 'No se pudo procesar la solicitud,intentalo mas tarde' })
  const [conductores, setconductores] = useState([])
  let { loading, data } = useGetAction('usuarios/obtenerconductores')

console.log(item);

  useEffect(() => {
    if (!loading) {
      setconductores(data);
    }
  }, [data, loading])


  const [selectedChofer, setSelectedChofer] = useState('');

  // Maneja el cambio en el combobox
  const handleChange = (event) => {
    setSelectedChofer(event.target.value);
  };

  let Actualizarchofer = async () => {

    let nuevoitem = { ...item }
    nuevoitem.chofer = selectedChofer

    let res = await POSTAction(nuevoitem, "despachosyfacturas/actualizardespacho")

    setShowPopup(true);
    setmsgpopup({ tipo: 'exito', msg: "Conductor asignado correctamente" })
    if (!res.succes) {
      setmsgpopup({ tipo: 'error', msg: res.estado })
    }
    await sleep(2000)
    setTimeout(() => {
      setShowPopup(false);
    }, 4000);
    return res.succes && handleModal();
  }

  const handlecaja=(e,index)=>{
    const {value}=e.target
    const newobjecto={...item}
    newobjecto.productosenviados[index].cajas=value
    setitem(newobjecto)

  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {showPopup && <Popup tipo={msgpopup.tipo} message={msgpopup.msg} />}
      <HeaderModal titulo='Asignación Conductor' handleModal={handleModal} />
      <hr />
      <br />
      <div><label className='modifier-titulo' htmlFor="chofer-select">Selecciona un Conductor</label>&nbsp; &nbsp;&nbsp;
        <select
          id="chofer-select"
          value={selectedChofer}
          onChange={handleChange}
          className='modifier-input-selct'
        >
          <option value="">Conductores</option>
          {conductores.map((chofer) => (
            <option key={chofer._id} value={chofer._id}>
              {chofer.nombres} {chofer.apellidos}
            </option>
          ))}
        </select></div>
      <br />
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: '5px' }}>
        <strong> Asignacion de cajas</strong>
        <strong>numero de cajas</strong>
      </div>
      <br />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        {item?.productosenviados?.map((producto, index) => (
          <div key={index} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '5px' }}>
            <span>{producto.nombre[0].nombre}</span> 
            <br />
            
            <input style={{padding:'3px',borderRadius:'3px',border:'1px solid gray'}} type="number" value={item.productosenviados.cajas} placeholder='Numero de cajas asignado' onChange={(e)=>handlecaja(e,index)}/></div>
        ))}
      </div>
      <div className='element-save-bar'>
        <button className="element-boton cerrar" onClick={() => handleModal()}>Cerrar</button>
        <button className="element-boton guardar" onClick={() => { Actualizarchofer() }} style={{ width: '120px' }}>Guardar</button>
      </div>


    </div>
  )
}
